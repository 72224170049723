export type NavigationItem = {
    title: string;
    path?: string;
    icon?: string;
    type?: string;
    children?: Array<GroupItem | ChildItem>;
};

export interface ChildItem {
    title: string;
    path: string;
    icon: string;
    type: string;
    children?: ChildItem[];
}

export interface GroupItem {
    title: string;
    icon: string;
    path?: string;
    type: string;
    children: ChildItem[];
}


const navigationConfig: NavigationItem[] = [
    {
        title: 'Home',
        path: '/',
        icon: 'home',
        type: 'link'
    },
    {
        title: 'About Us',
        path: '/about-us',
        icon: 'info',
        type: 'link'
    },

    // {
    //     title: 'Cleaning Services',
    //     path: '/cleaning-services',
    //     icon: 'cogs',
    //     type: 'group',
    //     children: [
    //         {
    //             title: 'Domestic Cleaning',
    //             path: '/cleaning-services/domestic-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'Eco Friendly Cleaning Services',
    //             path: '/cleaning-services/eco-friendly-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'Commercial Cleaning',
    //             path: '/cleaning-services/commercial-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'One Off Deep Cleaning',
    //             path: '/cleaning-services/one-off-deep-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'End Of Tenancy Cleaning Services',
    //             path: '/cleaning-services/end-of-tenancy-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'Carpet Cleaning Services',
    //             path: '/cleaning-services/carpet-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         },
    //         {
    //             title: 'Oven Cleaning',
    //             path: '/cleaning-services/oven-cleaning',
    //             icon: 'cogs',
    //             type: 'link'
    //         }
    //     ]
    // },
    {
        title: 'Programmes',
        path: '/programmes',
        icon: 'cogs',
        type: 'link'
    }
]

export default navigationConfig;