import React from 'react';
import { motion } from 'framer-motion';

const HeroSection: React.FC = () => {
    const textVariantsLeftToRight = {
        hidden: { opacity: 0, x: '-100vw' },
        visible: { opacity: 1, x: 0, transition: { duration: 1.3 } },
    };

    const textVariantsRightToLeft = {
        hidden: { opacity: 0, x: '100vw' },
        visible: { opacity: 1, x: 0, transition: { duration: 1.3 } },
    };

    const imageVariants = {
        hidden: { opacity: 0, scale: 0 },
        visible: { opacity: 1, scale: 1, transition: { duration: 1.5 } },
    };

    const blobVariants = {
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1, transition: { duration: 2, ease: 'easeInOut' } },
    };

    return (
        <div className="p-8 flex flex-col md:flex-row gap-4 h-screen items-center justify-center bg-[#DEE7E3]">
            <div className="w-full text-center md:w-1/2 text-3xl md:text-4xl lg:text-5xl xl:text-6xl order-2 md:order-1">
                <div className="flex gap-2 justify-center md:justify-start">
                    <motion.div initial="hidden" animate="visible" variants={textVariantsLeftToRight}>
                        <span className="text-tertiaryOrangeLight font-bold" data-aos="fade-right" data-aos-duration="1300">Best </span>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={textVariantsRightToLeft}>
                        <span className="text-primaryColor font-bold" data-aos="fade-left" data-aos-duration="1300">Way</span>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={textVariantsRightToLeft}>
                        <span className="text-primaryColor font-bold" data-aos="fade-left" data-aos-duration="1300">To</span>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={textVariantsRightToLeft}>
                        <span className="text-primaryColor font-bold" data-aos="fade-left" data-aos-duration="1300">Achieve</span>
                    </motion.div>
                </div>
                <div className="flex gap-2 justify-center md:justify-start">
                    <motion.div initial="hidden" animate="visible" variants={textVariantsRightToLeft}>
                        <span className="text-primaryColor font-bold" data-aos="fade-right" data-aos-duration="1300">Your </span>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={textVariantsLeftToRight}>
                        <span className="text-tertiaryOrangeLight font-bold" data-aos="fade-left" data-aos-duration="1300">Academic </span>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={textVariantsRightToLeft}>
                        <span className="text-primaryColor font-bold" data-aos="fade-right" data-aos-duration="1300">Goals</span>
                    </motion.div>
                </div>
            </div>
            <div className="relative w-[70%] sm:w-[50%] md:w-1/2 h-[50%] sm:h-[65%] md:h-[60%] lg:h-[100%] flex justify-center items-center overflow-hidden order-1 md:order-2">
                <motion.img
                    src="assets/images/background/bg-image-2.png"
                    alt="Background Blob 2"
                    className="absolute w-[80%] top-[5%] md:top-[10%] lg:top-[0%] left-[20%] transform -translate-x-1/2 -translate-y-1/2"
                    initial="hidden"
                    animate="visible"
                    variants={blobVariants}
                />
                <motion.img
                    src="assets/images/background/bg-image-3.png"
                    alt="Background Blob 3"
                    className="absolute w-[90%] top-[40%] left-[40%] transform -translate-x-1/2 -translate-y-1/2"
                    initial="hidden"
                    animate="visible"
                    variants={blobVariants}
                />
                <motion.img
                    src="assets/images/background/bg-image-4.png"
                    alt="Background Blob 4"
                    className="absolute w-[70%] top-[40%] right-[30%] transform -translate-x-1/2 -translate-y-1/2"
                    initial="hidden"
                    animate="visible"
                    variants={blobVariants}
                />
                <div className="relative w-full pt-0 md:w-[90%] md:pt-16">
                    <motion.img
                        src="assets/images/background/bg-image-100.png"
                        alt="Student"
                        initial="hidden"
                        animate="visible"
                        variants={imageVariants}
                    />
                    <div className="absolute w-[100%] h-10 justify-around z-[50] bottom-0 flex">
                        <div className='bg-gradient-to-t from-[#DEE7E3] to-transparent h-10 w-[65%]'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
