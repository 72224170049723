import React from 'react';

const MissionVisionGoals: React.FC = () => {

    return (
        <>
            <section className='bg-white'>
                <div className="container mx-auto overflow-hidden py-12 md:py-10 flex flex-col md:flex-row justify-center md:items-center gap-5 md:gap-32">
                    <div className="w-full md:w-1/2 space-y-10 order-2 md:order-1" data-aos="fade-right" data-aos-duration="1300">
                        <div className='space-y-5'>
                            <div className="text-2xl md:text-5xl flex items-center gap-2">
                                <div className="text-tertiaryOrangeLight font-light">
                                    Our
                                </div>
                                <div className="text-primaryColor font-bold">
                                    Mission
                                </div>
                            </div>
                            <p className="mt-4 text-justify">Royal Institute Epsom (RIE) is a New Zealand government registered academic institute. Its mission is to assist students in the very best way to achieve their academic goals.</p>
                        </div>
                        <div className='space-y-5'>
                            <div className="text-2xl md:text-5xl flex items-center gap-2">
                                <div className="text-tertiaryOrangeLight font-light">
                                    Our
                                </div>
                                <div className="text-primaryColor font-bold">
                                    Vision
                                </div>
                            </div>
                            <p className="mt-4 text-justify">RIE's vision is to provide excellent educational opportunities that are responsive to the needs of our students and prepare them to meet and exceed challenges as active participants in shaping the future of our world.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 flex items-center relative order-1 md:order-2" data-aos="fade-left" data-aos-duration="1300">
                        <img
                            src="assets/images/background/bg-image-12.png"
                            alt="Our Vision"
                            className='z-[2]'
                        />
                        <img
                            src="assets/images/background/bg-image-13.png"
                            alt="Our Mission"
                            className='absolute top-0 right-0 w-[30%] object-cover z-[1]'
                        />
                    </div>
                </div>
            </section>
            <section style={{ backgroundImage: 'url(assets/images/background/bg-image-14.png)', backgroundSize: 'cover' }}>
                <div className="container mx-auto overflow-hidden py-12 md:py-10 flex flex-col md:flex-row justify-center md:items-center gap-5 md:gap-32">
                    <div className="w-full md:w-1/2" data-aos="fade-right" data-aos-duration="1300">
                        <img src="assets/images/background/bg-image-15.png"
                            alt="Our Goals"
                        />
                    </div>
                    <div className="w-full md:w-1/2 space-y-5" data-aos="fade-left" data-aos-duration="1300">
                        <div className="text-2xl md:text-5xl flex items-center gap-2">
                            <div className="text-tertiaryOrangeLight font-light">
                                Our
                            </div>
                            <div className="text-primaryColor font-bold">
                                Goals
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className='text-justify'>
                                Royal Institute Epson (RIE) is a New Zealand government registered academic institute.
                                Its mission is to “Assist students in the very best way to achieve their academic goals”.
                            </p>
                        </div>
                        <div className=" mt-4 space-y-5">
                            <div className="flex gap-4">
                                <div>
                                    <img src="assets/images/background/bg-image-16.png"
                                        alt="Our Goals"
                                        className="w-6 h-6"
                                    />
                                </div>
                                <div>
                                    <span className="font-bold">Student Success</span>
                                    <p>Meeting student needs by creating an educational environment</p>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div>
                                    <img src="assets/images/background/bg-image-16.png"
                                        alt="Our Goals"
                                        className="w-6 h-6"
                                    />
                                </div>
                                <div>
                                    <span className="font-bold">Excellence</span>
                                    <p>Maintaining a high standard of integrity and performance</p>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div>
                                    <img src="assets/images/background/bg-image-16.png"
                                        alt="Our Goals"
                                        className="w-6 h-6"
                                    />
                                </div>
                                <div>
                                    <span className="font-bold">Collaboration</span>
                                    <p>Seeking input from all sources and respective industries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ >
    );
};

export default MissionVisionGoals;
