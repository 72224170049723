import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContext';
import { CustomError } from '../../../types';
import { InputField } from '../../components';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { user, login } = useAppContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        e.preventDefault();
        try {
            setLoading(true);
            if (!email || !password) {
                setError('Please provide both email and password.');
                return;
            }

            login({ email, password }).then(() => {
                setLoading(false);
            })

        } catch (error) {
            setError(String((error as CustomError).response?.data?.message));
        }
    };

    console.log("is loading", loading);


    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const handleInputChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        switch (name) {
            case 'email':
                setEmail(value);
                if (!emailRegex.test(value) && value.length > 0) {
                    setError('Invalid email');
                } else {
                    setError(null);
                }
                break;
            case 'password':
                setPassword(value);
                if (!passwordRegex.test(value) && value.length > 0) {
                    setError('Minimum eight characters, at least one letter, one number and one special character');
                } else {
                    setError(null);
                }
                break;
            default:
                break;
        }
    }

    // clear error messages after 5 seconds
    useEffect(() => {
        const timeout = setTimeout(() => {
            setError(null);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [error]);

    useEffect(() => {
        const loggedToken = localStorage.getItem("accessToken") || null;
        if (loggedToken && user) {
            navigate(getRedirectPath(user.userType ?? 0));
        }
    }, [user, navigate]);

    const getRedirectPath = (userType: number) => {
        switch (userType) {
            case Number(process.env.REACT_APP_ADMIN):
                return "/admin/dashboard";
            case Number(process.env.REACT_APP_MANAGER):
                return "/admin/dashboard";
            case Number(process.env.REACT_APP_USER):
                return "/";
            default:
                return "/";
        }
    };

    return (
        <div className='flex flex-row h-[100vh] relative'>
            <div className="w-full md:w-[50%] bg-white h-full flex flex-col justify-center items-center gap-5">
                <button onClick={() => navigate("/")} className='absolute top-5 left-5 text-primaryColor font-bold text-lg'>
                    <i className="fas fa-chevron-left mr-4"></i>
                    Back to Home
                </button>
                <form className='container w-full sm:w-[400px] space-y-5' onSubmit={handleLogin}>
                    <img
                        src="/assets/images/logo/royal-institute-logo.png"
                        alt="Profile"
                        className="h-10 my-2"
                    />
                    <h1 className='text-2xl font-bold text-primaryColor text-left uppercase'>LogIn</h1>

                    <InputField
                        label='Email'
                        placeholder='Email'
                        type='text'
                        id='email'
                        name='email'
                        value={email}
                        onChange={(e) => handleInputChange(e)}
                        className='border border-gray-300 rounded-none px-2 py-3'
                    />

                    <InputField
                        label='Password'
                        placeholder='Password'
                        type='password'
                        id='password'
                        name='password'
                        value={password}
                        onChange={(e) => handleInputChange(e)}
                        className='border border-gray-300 rounded-none px-2 py-3'
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}

                    <button
                        type="submit"
                        className="bg-primaryColor text-white py-4 text-xs w-full hover:bg-secondaryColor disabled:opacity-50 disabled:cursor-not-allowed uppercase transition-all duration-300 ease-in-out"
                        disabled={error !== null || !email || !password || loading}
                    >
                        {loading ? 'Loading...' : 'Login'}
                    </button>

                </form>
                {/* <div className='container w-full sm:w-[400px] flex flex-col gap-5'>
                    <div className='flex flex-row justify-between items-center gap-2 text-xs'>
                        <div className='whitespace-nowrap flex items-center gap-2'>
                            <p className='text-center'>Don't have an account?</p>
                            <Link to="/register" className='hover:text-secondaryColor text-primaryColor font-medium text-center underline'>Register</Link>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="hidden relative w-[50%] bg-bgImage1 h-full md:flex justify-center items-center bg-cover bg-center bg-no-repeat">
                <div className='absolute bg-primaryColor w-full h-full'></div>
                <div className='absolute text-white text-center justify-center flex flex-col items-center'>
                    <img
                        src="/assets/images/logo/royal-institute-logo-wide.png"
                        alt="Profile"
                        className="h-16 my-5"
                    />
                    <h1 className='text-4xl font-bold'>Welcome to Royal Institute Epsom </h1>
                    <p className='text-lg'>Admin Panel</p>
                    <p className='text-lg'>Login to your account</p>
                </div>
            </div>

        </div>
    );
};

export default Login;
