import React, { useEffect, useState } from 'react'
import { AdminHeadTitle, InputField, Modal } from '../../components'
import { DELETE, GET, POST, PUT } from '../../auth/AxiosHelper';
import { toast } from 'react-toastify';
import ViewProducts from './view-product';
import { CustomError, PosterIf } from '../../../types';

const Products: React.FC = () => {
    const [orders, setOrders] = useState<PosterIf[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState<string | null>(null);
    const [currentOffer, setCurrentOffer] = useState<PosterIf | null>(null);

    const [order, setOrder] = useState<number | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    const [inputKey, setInputKey] = useState<number>(Date.now());
    const [deleteConfirm, setDeleteConfirm] = useState('');

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const valid = file?.size <= 1024 * 1024 * 2;
            if (!valid) {
                toast.error('File size exceeds the limit of 2MB');
                return;
            }
            setImage(file);
        }
    }

    const handleRemoveImage = () => {
        if (imagePreviewUrl) {
            URL.revokeObjectURL(imagePreviewUrl);
        }
        setImage(null);
        setImagePreviewUrl(null);
        setInputKey(Date.now()); // Change the key to force re-render
    }

    useEffect(() => {
        if (image) {
            const url = URL.createObjectURL(image);
            setImagePreviewUrl(url);

            return () => {
                URL.revokeObjectURL(url);
            }
        }
    }, [image]);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const response = await GET('/poster/admin/all');
            if (response.status === 200) {
                const data = response.data.data.map((item: PosterIf) => ({
                    ...item,
                    // image: process.env.REACT_APP_API_URL + '/' + item.image
                    image: process.env.REACT_APP_API_URL + '/' + item.image
                }));
                setOrders(data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const handleModalOpen = (type: string, data: PosterIf) => {
        setCurrentOffer(data);
        setModalType(type);
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentOffer(null);
        setModalType(null);
        setImage(null);
        setImagePreviewUrl(null);
        setInputKey(Date.now());
        setDeleteConfirm('');
        setOrder(null);
    }

    const handleAddSave = async () => {
        try {
            const formData = new FormData();
            formData.append('image', image ? image : '');
            formData.append('order', order ? order.toString() : '');

            const response = await POST('/poster/upload', formData, { contentType: 'multipart/form-data' });
            if (response.status === 201) {
                toast.success('Poster added successfully');
                fetchProducts();
                handleModalClose();
                setImage(null);
                setOrder(null);
            }
        } catch (error) {
            console.error(error);
            toast.error((error as CustomError).response?.data?.message);
        }
    }

    const handleEditSave = async () => {
        try {

            const payload = {
                order: order
            }

            const response = await PUT(`/poster/update/${currentOffer?._id}`, payload);
            if (response.status === 200) {
                toast.success('Poster updated successfully');
                fetchProducts();
                handleModalClose();
            }
        } catch (error) {
            console.error(error);
            toast.error((error as CustomError).response?.data?.message ?? 'Failed to update poster');
        }
    }

    const handleActiveDeactive = async () => {
        try {
            const response = await PUT(`/poster/active-deactive/${currentOffer?._id}`);
            if (response.status === 200) {
                toast.success(`Poster ${currentOffer?.isActive ? 'deactivated' : 'activated'} successfully`);
                fetchProducts();
                setDeleteConfirm('');
                handleModalClose();
            }
        } catch (error) {
            console.error(error);
            toast.error((error as CustomError).response?.data?.message ?? 'Failed to deactivate poster');
        }
    }

    const handleDelete = async () => {
        try {
            const response = await DELETE(`/poster/delete/${currentOffer?._id}`);
            if (response.status === 200) {
                toast.success('Poster deleted successfully');
                fetchProducts();
                handleModalClose();
            }
        } catch (error) {
            console.error(error);
            toast.error((error as CustomError).response?.data?.message ?? 'Failed to delete poster');
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    const getModalContent = () => {
        if (modalType === 'add') {
            return (
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <div>
                        <div className='grid grid-cols-1 gap-5'>
                            {imagePreviewUrl ? (
                                <div className='relative w-full min-h-[200px] lg:min-h-[300px] rounded-lg overflow-hidden'>
                                    <button
                                        className=" absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 cursor-pointer w-[30px]"
                                        onClick={handleRemoveImage}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                    <img
                                        src={imagePreviewUrl}
                                        alt=""
                                        className='w-full h-full object-cover'
                                    />
                                </div>
                            ) :
                                <div className="col-span-2 flex gap-3 flex-col justify-center items-center h-[400px]">
                                    <i className="fas fa-image fa-2x text-gray-500"></i>
                                    <span className="ml-2">No images selected</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="relative">
                        <div className='sticky top-0 space-y-5'>
                            <InputField
                                type='number'
                                label='Order'
                                id='order'
                                name='order'
                                placeholder='Enter order'
                                value={order}
                                onChange={(e) => setOrder(parseInt(e.target.value))}
                                required
                                min={1}
                            />
                            <div>
                                <label htmlFor="file" className="block text-gray-700 text-sm font-bold">
                                    Image     <span className="text-red-500">*</span>
                                    <span className="text-xs text-gray-500 block">Max 2MB per image</span>
                                </label>
                                <label
                                    htmlFor="file"
                                    className="flex cursor-pointer items-center whitespace-nowrap justify-center bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out gap-10 px-4 py-2 w-full"
                                >
                                    <i className="fas fa-upload"></i>Choose Image
                                </label>
                                <input
                                    key={inputKey}
                                    type="file"
                                    id="file"
                                    multiple={false}
                                    onChange={handleImageChange}
                                    accept="image/*"
                                    className="hidden"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (modalType === 'edit' && currentOffer) {
            return (
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <div>
                        <div className='grid grid-cols-1 gap-5'>
                            <div className='relative w-full min-h-[200px] lg:min-h-[300px] rounded-lg overflow-hidden'>
                                <img
                                    src={currentOffer.image}
                                    alt=""
                                    className='w-full h-full object-cover'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <div className='sticky top-0 space-y-5'>
                            <InputField
                                type='number'
                                label='Order'
                                id='order'
                                name='order'
                                placeholder='Enter order'
                                value={order ?? currentOffer.order}
                                onChange={(e) => setOrder(parseInt(e.target.value))}
                                required
                                min={0}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        if (modalType === 'deactivate' && currentOffer) {
            return <div className='space-y-5'>
                <p>Are you sure you want to {currentOffer.isActive ? 'deactivate' : 'activate'} this poster?</p>
                <InputField
                    type='text'
                    id='deactivate'
                    name='deactivate'
                    placeholder='YES'
                    label={`Type 'YES' to ${currentOffer.isActive ? 'deactivate' : 'activate'}`}
                    value={deleteConfirm}
                    onChange={(e) => setDeleteConfirm(e.target.value)}
                    required
                />
            </div>;
        }

        if (modalType === 'delete' && currentOffer) {
            return <div className='space-y-5'>
                <p>Are you sure you want to delete this poster?</p>
                <InputField
                    type='text'
                    id='delete'
                    name='delete'
                    placeholder='YES'
                    label='Type YES to delete'
                    value={deleteConfirm}
                    onChange={(e) => setDeleteConfirm(e.target.value)}
                    required
                />
            </div>;
        }

        return null;
    }

    return (
        <div className='p-4'>
            <AdminHeadTitle
                title='Products'
                subtitle='Manage Products for Customers'
                content={
                    <button
                        onClick={() => handleModalOpen('add', {} as PosterIf)}
                        className='px-4 py-2 w-full bg-primaryColor hover:bg-secondaryColor text-white rounded'
                    >
                        Add Poster
                    </button>}
            />
            {isLoading ?
                <div className="flex gap-3 flex-col justify-center items-center h-[calc(100vh-200px)]">
                    <i className="fas fa-spinner fa-spin fa-2x"></i>
                    <span className="ml-2">Loading...</span>
                </div>
                : orders.length > 0 ?
                    <ViewProducts products={orders} handleModalOpen={handleModalOpen} />
                    : orders.length === 0 ?
                        <div className="flex gap-3 flex-col justify-center items-center h-[calc(100vh-200px)]">
                            <i className="fas fa-exclamation-triangle fa-2x text-yellow-500"></i>
                            <span className="ml-2">No special offers found</span>
                        </div>
                        : null
            }

            {isModalOpen && (
                <Modal
                    size='lg'
                    title={modalType === 'add' ? 'Add Poster'
                        : modalType === 'edit' ? 'Edit Poster'
                            : modalType === 'deactivate' ? currentOffer?.isActive ? 'Deactivate Poster' : 'Activate Poster'
                                : modalType === 'delete' ? 'Delete Poster'
                                    : ''}
                    content={getModalContent()}
                    onClose={handleModalClose}
                    onConfirm={modalType === 'add' ? handleAddSave
                        : modalType === 'edit' ? handleEditSave
                            : modalType === 'deactivate' ? handleActiveDeactive
                                : modalType === 'delete' ? handleDelete
                                    : handleModalClose}
                    confirmText={modalType === 'add' ? 'Add'
                        : modalType === 'edit' ? 'Save'
                            : modalType === 'deactivate' ? currentOffer?.isActive ? 'Deactivate' : 'Activate'
                                : modalType === 'delete' ? 'Delete'
                                    : 'Confirm'}
                    cancelText="Cancel"
                    confirmButtonClass={modalType === 'add' ? 'bg-primaryColor hover:bg-secondaryColor'
                        : modalType === 'edit' ? 'bg-blue-600 hover:bg-blue-700'
                            : modalType === 'deactivate' ? currentOffer?.isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                                : modalType === 'delete' ? 'bg-red-500 hover:bg-red-600'
                                    : 'bg-primaryColor hover:bg-secondaryColor'}
                    disableConfirm={
                        modalType === 'add' ? !image
                            : modalType === 'edit' ? order === null
                                : modalType === 'deactivate' ? deleteConfirm !== 'YES'
                                    : modalType === 'delete' ? deleteConfirm !== 'YES'
                                        : true}
                />
            )}
        </div>
    )
}

export default Products;
