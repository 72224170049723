import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CustomError } from '../../../types';
import { InputField } from '../../components';
import { POST } from '../../auth/AxiosHelper';
import { toast } from 'react-toastify';

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    const [inputKey, setInputKey] = useState<number>(Date.now());

    const handleAddSave = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {

            const formData = new FormData();
            formData.append('name', name);
            formData.append('username', username);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('image', profileImage ? profileImage : '');

            const response = await POST('/user/create', formData, { contentType: 'multipart/form-data' });
            if (response.status === 201) {
                toast.success('User added successfully');
                setName('');
                setUsername('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                navigate('/');
            }
        } catch (error) {
            console.error(error);
            toast.error((error as CustomError).response?.data?.message);
        }
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const handleInputChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        switch (name) {
            case 'name':
                setName(value);
                if (!value) {
                    setError('Name is required');
                } else {
                    setError(null);
                }
                break;
            case 'username':
                setUsername(value);
                if (!value) {
                    setError('Username is required');
                } else {
                    setError(null);
                }
                break;
            case 'email':
                setEmail(value);
                if (!emailRegex.test(value) && value.length > 0) {
                    setError('Invalid email');
                } else {
                    setError(null);
                }
                break;
            case 'password':
                setPassword(value);
                if (!passwordRegex.test(value) && value.length > 0) {
                    setError('Minimum eight characters, at least one letter, one number, and one special character');
                } else {
                    setError(null);
                }
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                if (password !== value) {
                    setError('Passwords do not match');
                } else {
                    setError(null);
                }
                break;

            default:
                break;
        }
    };

    const handleRemoveImage = () => {
        if (imagePreviewUrl) {
            URL.revokeObjectURL(imagePreviewUrl);
        }
        setProfileImage(null);
        setImagePreviewUrl(null);
        setInputKey(Date.now()); // Change the key to force re-render
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const valid = file?.size <= 1024 * 1024 * 2;
            if (!valid) {
                toast.error('File size exceeds the limit of 2MB');
                return;
            }
            setProfileImage(file);
        }
    }

    useEffect(() => {
        if (profileImage) {
            const url = URL.createObjectURL(profileImage);
            setImagePreviewUrl(url);

            return () => {
                URL.revokeObjectURL(url);
            }
        }
    }, [profileImage]);



    useEffect(() => {
        const timeout = setTimeout(() => {
            setError(null);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [error]);

    useEffect(() => {
        const loggedToken = localStorage.getItem("accessToken") || null;
        if (loggedToken) {
            navigate("/");
        }
    }, [navigate]);

    return (
        <div className='flex flex-row h-[100vh] relative'>
            <div className="w-full md:w-[50%] bg-white h-full flex flex-col justify-center items-center gap-5">
                <form className='container w-full sm:w-[400px] space-y-5' onSubmit={handleAddSave}>
                    <h1 className='text-2xl font-bold text-primaryColor text-center uppercase'>Register</h1>
                    <InputField
                        label='Name'
                        placeholder='Name'
                        type='text'
                        id='name'
                        name='name'
                        value={name}
                        onChange={handleInputChange}
                        className='border border-gray-300 rounded-none px-2 py-3'
                        required
                    />
                    <InputField
                        label='Username'
                        placeholder='Username'
                        type='text'
                        id='username'
                        name='username'
                        value={username}
                        onChange={handleInputChange}
                        className='border border-gray-300 rounded-none px-2 py-3'
                        required
                    />
                    <InputField
                        label='Email'
                        placeholder='Email'
                        type='text'
                        id='email'
                        name='email'
                        value={email}
                        onChange={handleInputChange}
                        className='border border-gray-300 rounded-none px-2 py-3'
                        required
                    />
                    <InputField
                        label='Password'
                        placeholder='Password'
                        type='password'
                        id='password'
                        name='password'
                        value={password}
                        onChange={handleInputChange}
                        className='border border-gray-300 rounded-none px-2 py-3'
                        required
                    />
                    <InputField
                        label='Confirm Password'
                        placeholder='Confirm Password'
                        type='password'
                        id='confirmPassword'
                        name='confirmPassword'
                        value={confirmPassword}
                        onChange={handleInputChange}
                        className='border border-gray-300 rounded-none px-2 py-3'
                        required
                    />

                    {imagePreviewUrl && (
                        <div className='relative w-full h-[200px] lg:h-[300px] rounded-lg overflow-hidden'>
                            <button
                                className=" absolute top-2 right-2 text-white bg-red-500 rounded-full p-1 cursor-pointer w-[30px]"
                                onClick={handleRemoveImage}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                            <img
                                src={imagePreviewUrl}
                                alt=""
                                className='w-full h-full object-cover'
                            />
                        </div>
                    )}
                    <div className="mb-4">
                        <label
                            htmlFor="file"
                            className="flex cursor-pointer items-center whitespace-nowrap justify-center bg-primaryColor text-white rounded-md hover:opacity-80 transition-all duration-300 ease-in-out gap-10 px-4 py-2 w-full"
                        >
                            <i className="fas fa-upload"></i>Choose Images
                        </label>
                        <input
                            key={inputKey} // Add key to force re-render
                            type="file"
                            id="file"
                            onChange={handleImageChange}
                            accept="image/*"
                            className="hidden"
                        />
                    </div>


                    {error && <div style={{ color: 'red' }}>{error}</div>}

                    <button
                        type="submit"
                        className="bg-primaryColor text-white py-4 text-xs w-full hover:bg-primaryColorLighter disabled:opacity-50 disabled:cursor-not-allowed uppercase"
                        disabled={!!error || !email || !password || !name || !username || !confirmPassword}
                    >
                        Register
                    </button>
                </form>
                <div className='container w-full sm:w-[400px] flex flex-col gap-5'>
                    <div className='flex flex-row justify-between items-center gap-2 text-xs'>
                        <div className='whitespace-nowrap flex items-center gap-2'>
                            <p className='text-center'>Already have an account?</p>
                            <Link to="/login" className='hover:text-secondaryColor text-primaryColor font-medium text-center underline'>Log In</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden relative w-[50%] bg-bgImage1 h-full md:flex justify-center items-center bg-cover bg-center bg-no-repeat">
                <div className='absolute bg-primaryColor w-full h-full'></div>
                <div className='absolute text-white text-center justify-center flex flex-col items-center'>
                    <img
                        src="/assets/images/logo/e-commerce-logo.png"
                        alt="Profile"
                        className="h-16 my-5"
                    />
                    <h1 className='text-4xl font-bold'>Welcome to E-Commerce</h1>
                    <p className='text-lg'>Please Register to continue</p>
                </div>
            </div>
        </div>
    );
};

export default Register;
