import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navbar.css';
import { NavigationItem } from '../../routes/navigationConfig';
interface NavBarProps {
    navigation: NavigationItem[];
}

const NavBar: React.FC<NavBarProps> = ({ navigation }) => {
    const [open, setOpen] = React.useState(false);
    // const [scrolling, setScrolling] = useState(false);
    const [subItem, setSubItem] = useState(false);
    const [subItem2, setSubItem2] = useState(false);
    const location = useLocation();

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY > 0) {
    //             setScrolling(true);
    //         } else {
    //             setScrolling(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const handleOpen = () => {
        if (open) {
            // If the menu is open, close it with animation
            const mobileMenuBg = document.getElementById('mobile-menu-bg');
            const mobileMenu = document.getElementById('mobile-menu');
            if (mobileMenuBg && mobileMenu) {
                mobileMenu.classList.remove('slide-in-right');
                mobileMenu.classList.add('slide-out-right');
                setTimeout(() => {
                    setOpen(false);
                }, 600); // Animation duration
            }
        } else {
            // If the menu is closed, open it
            setOpen(true);
        }
    };

    const handleSubItem = () => {
        setSubItem(!subItem);
    };

    const handleSubItem2 = () => {
        setSubItem2(!subItem2);
    };

    // when open sidebar hide the body scroll
    if (open) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }

    // const calculateQuantity = (cart: PosterIf[]) => {
    //     let quantity = 0;
    //     cart.forEach((item) => {
    //         quantity += Number(item.quantity);
    //     });
    //     return quantity;
    // }



    return (
        <>
            <nav
                className={`fixed w-full transition-colors z-[99] duration-300 bg-[#d4d8d7]`}
            >
                <div className="">
                    <div className="flex items-center justify-between">
                        <div className="flex-shrink-0 pl-10 md:pl-20 py-[0.90rem] md:py-3">
                            <Link
                                to="/"
                                className="flex items-center gap-2"
                            >
                                <img
                                    className="h-[40px] md:h-[45px]"
                                    src="assets/images/logo/royal-institute-logo.png"
                                    alt="Workflow"
                                />
                                <span className="text-[#23110C] font-bold text-[16px]">Royal Institute Epsom</span>
                            </Link>
                        </div>
                        <div className="hidden lg:block rounded-bl-full bg-primaryColor w-1/2  pr-10 md:pr-20 py-4">
                            <ul className="flex space-x-2 xl:space-x-4 justify-between pl-20">
                                {navigation.map((item, index) => (
                                    <li key={index}>
                                        {item.type === 'link' ? (
                                            <Link
                                                to={String(item.path)}
                                                className={`
                                    hover:text-secondaryColor px-3 py-2 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold 
                                    ${location.pathname === item.path
                                                        ? 'text-secondaryColor'
                                                        : 'text-secondaryColorDark'
                                                    }
                                    `}
                                            >
                                                {/* {item.icon &&  <i className={`fas fa-${item.icon}`}></i>  } */}
                                                {item.title}
                                                {String(item.type) === 'group' ? (
                                                    <i className="fas fa-chevron-down"></i>
                                                ) : (
                                                    ''
                                                )}
                                            </Link>
                                        ) : (
                                            <div className="dropdown-1">
                                                {item.path ? (
                                                    <Link
                                                        to={String(item.path)}
                                                        className={`hover:text-secondaryColor px-3 py-2 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold
                                                            ${item?.children?.some(
                                                            (child) =>
                                                                location.pathname ===
                                                                child.path
                                                        )
                                                                ? 'text-secondaryColor'
                                                                : 'text-secondaryColorDark'
                                                            }
                                                        `}
                                                    >
                                                        {item.title}
                                                        <i className="fas fa-chevron-down"></i>
                                                    </Link>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className={`hover:text-secondaryColor px-3 py-2 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold
                                                       ${item?.children?.some(
                                                            (child) =>
                                                                location.pathname ===
                                                                child.path
                                                        )
                                                                ? 'text-secondaryColor'
                                                                : `${item?.children?.some(
                                                                    (child) =>
                                                                        child?.children?.some(
                                                                            (
                                                                                subChild
                                                                            ) =>
                                                                                location.pathname ===
                                                                                subChild.path
                                                                        )
                                                                )
                                                                    ? 'text-secondaryColor'
                                                                    : 'text-secondaryColorDark'
                                                                }`
                                                            }
                                                `}
                                                    >
                                                        {item.title}
                                                        <i className="fas fa-chevron-down"></i>
                                                    </button>
                                                )}
                                                <ul className="absolute  dropdown-content-1 bg-gray-800 text-white">
                                                    {item.children?.map((child, index) => (
                                                        <li
                                                            key={index}
                                                            className="hover:text-primaryColor hover:bg-secondaryColor overflow-hidden"
                                                        >
                                                            {child.type === 'link' ? (
                                                                <Link
                                                                    to={String(child.path)}
                                                                    className={`px-5 py-2 flex items-center gap-2 text-[13px] capitalize font-bold transition-all duration-300 hover:translate-x-2
                                                                    ${location.pathname ===
                                                                            child.path
                                                                            ? 'text-primaryColor bg-secondaryColor'
                                                                            : 'text-secondaryColorDark'
                                                                        }
                                                                    `}
                                                                >
                                                                    {child.title}
                                                                </Link>
                                                            ) : (
                                                                <div className="dropdown-2">
                                                                    {child.path ? (
                                                                        <Link
                                                                            to={String(child.path)}
                                                                            className={`px-5 py-2 flex items-center gap-2 text-[13px] capitalize font-bold transition-all duration-300 hover:translate-x-2
                                                                            ${location.pathname ===
                                                                                    child.path
                                                                                    ? 'text-primaryColor bg-secondaryColor'
                                                                                    : `${child?.children?.some(
                                                                                        (
                                                                                            subChild
                                                                                        ) =>
                                                                                            location.pathname ===
                                                                                            subChild.path
                                                                                    )
                                                                                        ? 'text-primaryColor bg-secondaryColor'
                                                                                        : 'text-secondaryColorDark'
                                                                                    }`
                                                                                }
                                                                            `}
                                                                        >
                                                                            {child.title}
                                                                            <i className="fas fa-chevron-down"></i>
                                                                        </Link>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="px-5 py-2 flex items-center gap-2 text-[13px] capitalize font-bold transition-all duration-300 hover:translate-x-2"
                                                                        >
                                                                            {child.title}
                                                                            <i className="fas fa-chevron-down"></i>
                                                                        </button>
                                                                    )}
                                                                    <ul className="absolute dropdown-content-2 bg-gray-800 text-white">
                                                                        {child.children?.map((subChild, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className="hover:text-primaryColor hover:bg-secondaryColor"
                                                                            >
                                                                                <Link
                                                                                    to={String(subChild.path)}
                                                                                    className={`px-5 py-2 flex items-center gap-2 text-[13px] capitalize font-bold transition-all duration-300 hover:translate-x-2
                                                                                        ${location.pathname ===
                                                                                            subChild.path
                                                                                            ? 'text-primaryColor bg-secondaryColor'
                                                                                            : 'text-secondaryColorDark'
                                                                                        }
                                                                                    `}
                                                                                >
                                                                                    {subChild.title}
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                ))}

                                {/* {localStorage.getItem('accessToken') ? (
                                    <>
                                        <li>
                                            <Link
                                                to="/profile"
                                                className="hover:text-secondaryColor text-white px-3 py-3 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                            >
                                                <i className="fas fa-user"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/wishlist"
                                                className="hover:text-secondaryColor text-white px-3 py-3 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                            >
                                                <i className="fas fa-heart"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <button
                                                className="hover:text-secondaryColor text-white px-3 py-3 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                                onClick={() => {
                                                    localStorage.removeItem('accessToken');
                                                    localStorage.clear();
                                                    window.location.href = '/';
                                                }}
                                            >
                                                <i className="fas fa-sign-out-alt"></i>
                                            </button>
                                        </li>
                                    </>
                                ) : (
                                    <li>
                                        <Link
                                            to="/login"
                                            className="hover:text-secondaryColor text-white  px-3 py-2 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                        >
                                            Login
                                        </Link>
                                    </li>
                                )} */}

                            </ul>
                        </div>
                        {open ? null : (
                            <div className="flex lg:hidden items-center gap-2 z-[99] pr-10">
                                {/* {localStorage.getItem('accessToken') ? (
                                    <>
                                        <Link
                                            to="/profile"
                                            className="hover:text-secondaryColor text-white px-3 py-3 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                        >
                                            <i className="fas fa-user"></i>
                                        </Link>
                                        <Link
                                            to="/wishlist"
                                            className="hover:text-secondaryColor text-white px-3 py-3 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                        >
                                            <i className="fas fa-heart"></i>
                                        </Link>
                                        <button
                                            className="hover:text-secondaryColor text-white px-3 py-3 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                            onClick={() => {
                                                localStorage.removeItem('accessToken');
                                                localStorage.clear();
                                                window.location.href = '/';
                                            }}
                                        >
                                            <i className="fas fa-sign-out-alt"></i>
                                        </button>
                                    </>
                                ) : (
                                    <Link
                                        to="/login"
                                        className="hover:text-secondaryColor text-white  px-3 py-2 flex items-center gap-2 rounded-md text-[15px] capitalize font-bold"
                                    >
                                        Login
                                    </Link>
                                )} */}
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center p-1 rounded-md hover:text-primaryColor text-secondaryColor hover:bg-secondaryColor bg-primaryColor focus:outline-none"
                                    aria-controls="mobile-menu"
                                    aria-expanded="false"
                                    onClick={handleOpen}
                                >
                                    <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="menu"
                                        width="1.3em"
                                        height="1.3em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </div>

            </nav>
            <nav className={`fixed w-full ${open ? 'h-full z-[999]' : 'z-[99]'}`}>
                <div
                    className={`${open
                        ? 'absolute block z-[9999] top-0 bottom-0 right-0 left-0 bg-[#0000008a] transition-opacity'
                        : 'hidden'
                        } lg:hidden`}
                    id="mobile-menu-bg"
                    onClick={handleOpen}
                >
                    <div className="-mr-2 lg:hidden absolute top-0 left-0 p-2">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md hover:text-primaryColor text-secondaryColor hover:bg-secondaryColor bg-primaryColor hover:rotate-180 hover:duration-300 hover:transition-all"
                            onClick={handleOpen}
                        >
                            {/* <CloseIcon /> */}
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="close"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div
                    className={`${open
                        ? 'absolute block z-[9999] top-0 bottom-0 right-0 max-w-[75%] w-[400px] bg-primaryColor mobile-menu slide-in-right'
                        : 'hidden slide-out-right'
                        } lg:hidden`}
                    id="mobile-menu"
                >
                    <div className="w-full h-full float-end bg-menuBarImage bg-cover bg-center bg-no-repeat">
                        <Link className="flex w-full items-center justify-center" to="/">
                            <img
                                className="h-[35px] my-8"
                                src="assets/images/logo/royal-institute-logo-wide.png"
                                alt="Workflow"
                            />
                        </Link>
                        <div className="pt-2">
                            {navigation.map((item, index) => (
                                <div
                                    key={index}
                                    className={`border-[#9999999c] ${index === 0 ? 'border-y-[1.5px]' : 'border-b-[1.5px]'
                                        } `}
                                >
                                    {item.type === 'link' ? (
                                        <div className="hover:bg-secondaryColor">
                                            <Link
                                                onClick={handleOpen}
                                                to={String(item.path)}
                                                className={`
                                    hover:text-primaryColor px-3 py-2 flex items-center gap-2 text-[14px] capitalize font-bold transition-all duration-300 hover:translate-x-2
                                    ${location.pathname === item.path
                                                        ? 'text-primaryColor bg-secondaryColor'
                                                        : 'text-secondaryColorDark'
                                                    }
                                    `}
                                            >
                                                {/* {item.icon &&  <i className={`fas fa-${item.icon}`}></i>  } */}
                                                {item.title}
                                                {String(item.type) === 'group' ? (
                                                    <i className="fas fa-chevron-down"></i>
                                                ) : (
                                                    ''
                                                )}
                                            </Link>
                                        </div>
                                    ) : (
                                        <div>
                                            {item.path ? (
                                                <div
                                                    className={`flex items-center justify-between w-full text-[14px] capitalize  font-bold ${subItem ? 'bg-[#f6b53ce5]' : 'text-white'
                                                        }`}
                                                >
                                                    <Link
                                                        onClick={handleOpen}
                                                        to={String(item.path)}
                                                        className="pl-3 py-2 hover:text-primaryColor hover:bg-secondaryColor text-white w-full cursor-pointer"
                                                    >
                                                        {item.title}
                                                    </Link>
                                                    <button
                                                        type="button"
                                                        onClick={handleSubItem}
                                                        className="px-4 py-2 border-x-[1.5px] border-[#9999999c] text-white hover:text-primaryColor hover:bg-secondaryColor cursor-pointer"
                                                    >
                                                        <i className="fas fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={handleSubItem}
                                                    className={`flex pl-3 py-2 items-center justify-between w-full text-[14px] hover:text-primaryColor hover:bg-secondaryColor text-white cursor-pointer capitalize  font-bold 
                                                    ${subItem
                                                            ? 'bg-[#f6b53ce5]'
                                                            : 'text-secondaryColorDark'
                                                        }
                                                    ${item?.children?.some(
                                                            (child) =>
                                                                location.pathname ===
                                                                child.path
                                                        )
                                                            ? 'bg-[#f6b53ce5]'
                                                            : `${item?.children?.some(
                                                                (child) =>
                                                                    child?.children?.some(
                                                                        (subChild) =>
                                                                            location.pathname ===
                                                                            subChild.path
                                                                    )
                                                            )
                                                                ? 'bg-[#f6b53ce5]'
                                                                : 'text-secondaryColorDark'
                                                            }`
                                                        }
                                                    
                                                                                                    `}
                                                >
                                                    <span className="transition-all duration-300 hover:translate-x-2 flex w-full ">
                                                        {item.title}
                                                    </span>
                                                    <div className="px-4">
                                                        <i className="fas fa-chevron-down"></i>
                                                    </div>
                                                </button>
                                            )}
                                            {subItem && (
                                                <ul>
                                                    {item.children?.map((child, index) => (
                                                        <li
                                                            key={index}
                                                            className={`border-[#9999999c] ${index === 0
                                                                ? 'border-y-[1.5px]'
                                                                : 'border-b-[1.5px]'
                                                                }`}
                                                        >
                                                            {child.type === 'link' ? (
                                                                <div
                                                                    className="hover:bg-secondaryColor"
                                                                    onClick={handleOpen}
                                                                >
                                                                    <Link
                                                                        to={String(child.path)}
                                                                        className={`px-5 py-2 flex items-center gap-2 text-[14px] capitalize font-bold transition-all duration-300 hover:translate-x-2 hover:text-primaryColor
                                                                    ${location.pathname ===
                                                                                child.path
                                                                                ? 'text-primaryColor bg-secondaryColor'
                                                                                : 'text-white'
                                                                            }
                                                                        `}
                                                                    >
                                                                        {child.title}
                                                                    </Link>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {child.path ? (
                                                                        <div
                                                                            className={`flex items-center justify-between w-full text-[14px] capitalize  font-bold ${subItem2
                                                                                ? 'bg-[#f6b53cb6]'
                                                                                : 'text-white'
                                                                                } `}
                                                                        >
                                                                            <Link
                                                                                onClick={handleOpen}
                                                                                to={String(child.path)}
                                                                                className="hover:text-primaryColor hover:bg-secondaryColor text-white w-full cursor-pointer"
                                                                            >
                                                                                <span className="pl-5 py-2 transition-all duration-300 hover:translate-x-2 flex w-full ">
                                                                                    {child.title}
                                                                                </span>
                                                                            </Link>
                                                                            <button
                                                                                type="button"
                                                                                onClick={handleSubItem2}
                                                                                className="px-4 py-2 border-x-[1.5px] border-[#999] text-white hover:text-primaryColor cursor-pointer hover:bg-secondaryColor"
                                                                            >
                                                                                <i className="fas fa-chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            onClick={handleSubItem2}
                                                                            className={`flex pl-5 py-2 items-center justify-between w-full text-[14px] hover:text-primaryColor hover:bg-secondaryColor text-white cursor-pointer capitalize  font-bold ${subItem2
                                                                                ? 'bg-[#f6b53cb6]'
                                                                                : 'text-white'
                                                                                }`}
                                                                        >
                                                                            <span className="transition-all duration-300 hover:translate-x-2 flex w-full ">
                                                                                {child.title}
                                                                            </span>
                                                                            <div className="px-4">
                                                                                <i className="fas fa-chevron-down"></i>
                                                                            </div>
                                                                        </button>
                                                                    )}
                                                                    {subItem2 && (
                                                                        <ul className=" text-white">
                                                                            {child.children?.map(
                                                                                (subChild, index) => (
                                                                                    <li
                                                                                        key={index}
                                                                                        className="hover:bg-[#f6b53c71]"
                                                                                        onClick={handleOpen}
                                                                                    >
                                                                                        <Link
                                                                                            to={String(subChild.path)}
                                                                                            className={`px-8 py-2 flex items-center gap-2 text-[14px] capitalize font-bold transition-all duration-300 hover:translate-x-2 hover:text-primaryColor 
                                                                                        ${location.pathname ===
                                                                                                    subChild.path
                                                                                                    ? 'text-primaryColor bg-[#f6b53c71]'
                                                                                                    : 'text-white'
                                                                                                }
                                                                                            `}
                                                                                        >
                                                                                            {subChild.title}
                                                                                        </Link>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavBar;
