import React, { useEffect } from "react";
import MissionVisionGoals from "./widgets/missionVisionGoals";
import LearningInfo from "./widgets/learningInfo";
import Management from "./widgets/management";
import Staff from "./widgets/staff";
import Fees from "./widgets/fees";
import AboutHeader from "./widgets/aboutHeader";

const About: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <AboutHeader />
            <MissionVisionGoals />
            <LearningInfo />
            <Management />
            <Staff />
            <Fees />
        </div>
    );
};

export default About;
