import React, { useEffect } from 'react';
import ProgrammesHeader from './widgets/programmesHeader';
import IeltsSection from './widgets/ieltsSection';
import CourseSection from './widgets/courseSection';
import ImportantLinkSection from './widgets/importantLinkSection';


const Programmes: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <ProgrammesHeader />
            <IeltsSection />
            <CourseSection />
            <ImportantLinkSection />
        </div>
    );
};

export default Programmes;
