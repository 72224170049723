// src/components/Staff.js
import React from 'react';

const languageArray = [
    {
        id: 1,
        name: 'Maths & Physics',
        icon: 'assets/images/icons/calculator.png',
        subtitle: [
            {
                id: 1,
                name: 'Two Mechatronics Engineers',
            },
            {
                id: 2,
                name: 'Auckland university graduates',
            },
        ]
    },
    {
        id: 2,
        name: 'Chemistry',
        icon: 'assets/images/icons/flask.png',
        subtitle: [
            {
                id: 1,
                name: 'Chemical Engineer (PhD) Auckland Uni graduate',
            },
            {
                id: 2,
                name: 'Auckland Uni Medical Student',
            },
        ]
    },
    {
        id: 3,
        name: 'Biology',
        icon: 'assets/images/icons/plant.png',
        subtitle: [
            {
                id: 1,
                name: 'Researcher (PhD)',
            },
            {
                id: 2,
                name: 'Masters Student researching at Auckland University',
            },
        ]
    },
    {
        id: 4,
        name: 'Science',
        icon: 'assets/images/icons/microscope.png',
        subtitle: [
            {
                id: 1,
                name: 'Graduate Teacher',
            },
            {
                id: 2,
                name: 'Over 20 years tutoring in Auckland',
            },
        ]
    },
    {
        id: 5,
        name: 'English',
        icon: 'assets/images/icons/eng.png',
        subtitle: [
            {
                id: 1,
                name: 'Experience in teaching English to people of all ages',
            },
            {
                id: 2,
                name: 'Accredited English tutors',
            },
        ]
    },
    {
        id: 6,
        name: 'Accounting & Commerce',
        icon: 'assets/images/icons/accounting.png',
        subtitle: [
            {
                id: 1,
                name: 'University lecturer (PhD) Auckland Uni graduate',
            },
            {
                id: 2,
                name: 'Experience tutor',
            },
        ]
    },

]

const Staff: React.FC = () => {
    return (
        <section className="py-12 md:py-10 bg-white overflow-hidden">
            <div className="container mx-auto">
                <div className='flex flex-col w-full items-center' data-aos="fade-right" data-aos-duration="1300" >
                    <div className='flex flex-col'>
                        <h2 className="text-3xl font-bold text-primaryColor leading-8">Some of Staff Members</h2>
                        <div className='h-1 w-20 bg-tertiaryOrangeLight mt-3 mb-6'></div>
                    </div>
                </div>
                <p className="mt-4 text-justify">
                    RIE recruit professionals with teaching experience in respective subject areas.
                    Professionals like Gayan, James, Devaki, Chintha, Milidu, Sarath, Navidu, Panchali,
                    and others make your learning, exam practice perfect. They know where you are heading,
                    they have gone through the same path you are just entering.
                </p>
                <div className="grid  grid-cols-1 md:grid-cols-2 gap-2 mt-8">
                    {languageArray.map((language) => (
                        <div key={language.id} className="p-6 border bg-[#F7F7F7] rounded-xl flex items-center"
                            data-aos="fade-right"
                            data-aos-duration={language.id * 200}
                            data-aos-delay={language.id * 200}
                        >
                            <div className='mr-4 bg-white p-5 rounded-lg'>
                                <img
                                    src={language.icon}
                                    alt="icon"
                                    className='w-12 h-12 inline-block'
                                />
                            </div>
                            <div className='space-y-2'>
                                <h3 className="text-lg font-bold">
                                    {language.name}
                                </h3>
                                <ul className='space-y-1'>
                                    {language.subtitle.map((sub) => (
                                        <li key={sub.id} className='flex gap-2'>
                                            <img
                                                src="assets/images/icons/Chevron.png"
                                                alt="icon"
                                                className='w-[0.8rem] h-[0.8rem] mt-1 inline-block'
                                            />
                                            <span className='font-thin text-sm'>
                                                {sub.name}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Staff;
