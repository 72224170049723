import React from 'react'

const AboutHeader: React.FC = () => {
    return (
        <section className="pt-[calc(4rem+4px)]" >
            <section
                className="text-center overflow-hidden py-20"
                style={{ backgroundImage: 'url(assets/images/background/bg-image-7.png)', backgroundSize: 'cover' }}
            >
                <div className="flex gap-2 justify-center text-5xl md:text-5xl xl:text-7xl" >
                    <div className="font-bold text-tertiaryOrangeLight" data-aos="fade-right" data-aos-duration="1300"  >
                        About
                    </div>
                    <div className="font-bold text-primaryColor" data-aos="fade-left" data-aos-duration="1300" >
                        Us
                    </div>
                </div>
                <div className="mt-4 text-center flex justify-center" data-aos="fade-up" data-aos-duration="1300" >
                    <div className='w-[90%] md:w-[25%]'>
                        Read more about us, our Mission, Vision, Goals and many others you might know
                    </div>
                </div>
            </section>
        </section>
    )
}

export default AboutHeader