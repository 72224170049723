import React from 'react';

const LearningInfo: React.FC = () => {
    return (
        <div>
            <section className='bg-primaryColor'>
                <div className="overflow-hidden grid grid-cols-1 md:grid-cols-2">
                    <div className="px-12 md:px-28 py-20 md:py-20 flex flex-col justify-center w-full space-y-10 order-2 md:order-1" data-aos="fade-right" data-aos-duration="1300">
                        <div className="text-2xl md:text-4xl">
                            <div className="text-tertiaryOrangeLight">
                                Does every student learn the <span className="text-white font-bold">
                                    same way ?
                                </span>
                            </div>
                        </div>
                        <p className="mt-4 text-justify text-white">
                            Some students pick up the material quickly that the teachers are teaching,
                            and others do not. Some students may need extra help after school, but sometimes parents’ don’t recognise that their child needs help.
                            Actually for the students who fall a little behind or have trouble in a subject need someone to help them to come up to the expected standard.
                        </p>
                    </div>
                    <div className="w-full flex items-center order-1 md:order-2" data-aos="fade-left" data-aos-duration="1300" >
                        <img
                            src="assets/images/background/bg-image-17.png"
                            alt="Our Vision"
                            className='w-full h-full object-cover'
                        />
                    </div>
                </div>
            </section>
            <section className='bg-primaryColor'>
                <div className="overflow-hidden grid grid-cols-1 md:grid-cols-2">
                    <div className="w-full flex items-center order-1 md:order-1" data-aos="fade-right" data-aos-duration="1300">
                        <img
                            src="assets/images/background/bg-image-19.png"
                            alt="Our Vision"
                            className='w-full h-full object-cover'
                        />
                    </div>
                    <div className="px-12 md:px-28 py-20 md:py-20 flex flex-col justify-center w-full space-y-10 order-2 md:order-2" data-aos="fade-left" data-aos-duration="1300">
                        <div className="text-2xl md:text-4xl">
                            <div className="text-tertiaryOrangeLight">
                                Tutoring is for enrichment and it's all about <span className="text-white font-bold">
                                    improvement, growth and gaining more confidence!
                                </span>
                            </div>
                        </div>
                        <p className="mt-4 text-justify text-white">
                            Some students pick up the material quickly that the teachers are teaching,
                            and others do not. Some students may need extra help after school, but sometimes parents’ don’t recognise that their child needs help.
                            Actually for the students who fall a little behind or have trouble in a subject need someone to help them to come up to the expected standard.
                        </p>
                    </div>
                </div>
            </section>
            <section className='bg-primaryColor'>
                <div className="overflow-hidden grid grid-cols-1 md:grid-cols-2">
                    <div className="px-12 md:px-28 py-20 md:py-20 flex flex-col justify-center w-full space-y-10 order-2 md:order-1" data-aos="fade-right" data-aos-duration="1300">
                        <div className="text-2xl md:text-4xl">
                            <div className="text-tertiaryOrangeLight">
                                Adult <span className="text-white font-bold"> Learning </span>
                            </div>
                        </div>
                        <p className="mt-4 text-justify text-white">
                            Adult education gives mature learners the chance to increase their knowledge, develop new skills and gain helpful qualifications and credentials. It can improve the learner’s image, give them the confidence and knowledge they need to gain promotions in their current jobs or new positions, and allow them to try new things that they may not have had the opportunity to before.
                        </p>
                    </div>
                    <div className="w-full flex items-center order-1 md:order-2" data-aos="fade-left" data-aos-duration="1300">
                        <img
                            src="assets/images/background/bg-image-18.png"
                            alt="Adult Learning"
                            className='w-full h-full object-cover'
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LearningInfo;
