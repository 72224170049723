import React from 'react';

const Fees: React.FC = () => {
    return (
        <section
            className="text-center overflow-hidden py-12 md:py-20 min-h-[calc(100vh-4rem)]"
            style={{ backgroundImage: 'url(assets/images/background/bg-image-20.png)', backgroundSize: 'cover' }}
        >
            <div className="container mx-auto flex flex-col justify-center gap-10">
                <div className='space-y-6' data-aos="fade-right" data-aos-duration="1300" >
                    <div className="text-2xl md:text-4xl flex items-center gap-2">
                        <div className="text-tertiaryOrangeLight">
                            Our
                        </div>
                        <div className="text-primaryColor font-bold">
                            Fees
                        </div>
                    </div>
                    <div className="mt-4" data-aos="fade-right" data-aos-duration="1300" >
                        <p className='text-justify text-[18px]'>
                            Need to pay fees monthly, so please pay at the beginning of the month to the RIE account. All payments are through bank transfer only, if you need any assistance please talk to the management at any time.
                        </p>
                    </div>
                </div>
                <div className='space-y-6' data-aos="fade-right" data-aos-duration="1300" >
                    <div className="text-2xl md:text-4xl flex items-center gap-2">
                        <div className="text-tertiaryOrangeLight">
                            Bank
                        </div>
                        <div className="text-primaryColor font-bold">
                            Details
                        </div>
                    </div>
                    <div className="mt-4 text-left text-[18px]" data-aos="fade-right" data-aos-duration="1300" >
                        <div>
                            <span className="font-bold">Bank</span> : ANZ
                        </div>
                        <div>
                            <span className="font-bold">Account Name</span> : Royal Institute
                        </div>
                        <div>
                            <span className="font-bold">Account number</span> : 01-0815-0128371-00
                        </div>
                    </div>
                </div>
                <div className='space-y-6'>
                    <div className="text-2xl md:text-4xl flex items-center gap-2" data-aos="fade-right" data-aos-duration="1300" >
                        <div className="text-tertiaryOrangeLight">
                            Complaints
                        </div>
                        <div className="text-primaryColor font-bold">
                            & Suggestions
                        </div>
                    </div>
                    <div className="mt-4 text-left text-[18px]" data-aos="fade-right" data-aos-duration="1300" >
                        <p>Please forward your complaints and suggestions to the Director, these will be highly confidential.</p>
                    </div>
                    <div className="mt-4 text-left text-[18px]" data-aos="fade-right" data-aos-duration="1300" >
                        <p className='text-[#644333]'>Sydney Fernando JP, Ph.D. (USA), Post Dip Com. (UK), BSc Ag. Honours (SL)</p>
                        <p className='flex items-center gap-2'>
                            WEB:
                            <a
                                href="http://j3i.645.myftpupload.com/"
                                className="text-[#644333] hover:underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                http://j3i.645.myftpupload.com/
                            </a>

                        </p>
                        <p>FB: <a
                            href="https://www.facebook.com/Royal-Institute-Epsom-100135454846284/?modal=admin_todo_tour"
                            className="text-[#644333] hover:underline"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.facebook.com/Royal-Institute-Epsom-100135454846284/?modal=admin_todo_tour
                        </a></p>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Fees;
