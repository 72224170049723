import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppContext';
import { CustomError, UserIf } from '../../../types';
import { GET, PUT } from '../../auth/AxiosHelper';
import { toast } from 'react-toastify';
import { AdminHeadTitle, InputField, Modal } from '../../components';

const Profile: React.FC = () => {
    const { user } = useAppContext();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [editUser, setEditUser] = useState<UserIf>(user as UserIf);
    const [error, setError] = useState('');
    const [isEdit, setEdit] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState<string | null>(null);

    const handleEdit = () => {
        setEdit(!isEdit);
    }

    const fetchUser = async () => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const res = await GET(`user/me`);

            const user = res.data.data;
            setEditUser({
                ...user,
                profileImage: user?.profileImage ? process.env.REACT_APP_API_URL + '/' + user.profileImage : '/assets/images/logo/e-commerce-logo.png'
            });
        }
    }

    const handleModalOpen = (type: string) => {
        setModalType(type);
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setModalType(null);
        fetchUser();
        setEdit(false);
        setOldPassword('');
        setNewPassword('');
        setError('');
        // set name, email, username, userType
        setEditUser(
            {
                username: user?.username,
                email: user?.email,
                name: user?.name,
                userType: user?.userType,
            } as UserIf
        )
    }

    useEffect(() => {
        setEditUser({
            username: user?.username,
            email: user?.email,
            name: user?.name,
            userType: user?.userType,
        } as UserIf)
    }, [user?.email, user?.name, user?.userType, user?.username, isEdit]);

    useEffect(() => {
        fetchUser();
    }, []);

    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const handleInputPasswordChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        if (name === 'oldPassword') {
            setOldPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
            if (!regex.test(value)) {
                setError('Password must be at least 8 characters long and include one letter, one number, and one special character.');
            } else {
                setError('');
            }
        }
    }
    // user/change-password
    const handlePasswordChange = async () => {
        try {
            const data = {
                oldPassword,
                newPassword
            }

            const res = await PUT('user/change-password', data);
            if (res.status === 200) {
                setOldPassword('');
                setNewPassword('');
                setError('');
                toast.success('Password changed successfully.');
                handleModalClose();
            }

        } catch (error) {
            console.log(error);
            toast.error((error as CustomError).response?.data?.message || 'Something went wrong.');
        }
    }

    const handleEditUser = async () => {
        try {
            const res = await PUT('user/update-by-token', editUser);
            if (res.status === 200) {
                toast.success('User updated successfully.');
                setEdit(false);
                fetchUser();
                handleModalClose();
            }
        } catch (error) {
            console.log(error);
            toast.error((error as CustomError).response?.data?.message || 'Something went wrong.');
        }
    }

    const getModalContent = () => {
        if (modalType === 'edit') {
            return (
                <div className='flex flex-col gap-5'>
                    <p className='text-primaryColor'>Are you sure you want to save changes?</p>
                </div>
            );
        }

        if (modalType === 'change-password') {
            return (
                <div className='flex flex-col gap-5'>
                    <p className='text-primaryColor'>Are you sure you want to change your password?</p>
                </div>
            );
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='p-4 space-y-5'>
            <AdminHeadTitle
                title='Profile'
                subtitle='Manage Profile'
            />
            <div className='bg-white w-full rounded-md overflow-hidden p-5 shadow-md space-y-5'>
                <div className='flex gap-5 md:items-center flex-col md:flex-row'>
                    <img
                        src={editUser?.profileImage ? editUser.profileImage : `${process.env.REACT_APP_API_URL}/uploads/default_profile.png`}
                        alt=" avatar"
                        className='border-4 border-gray-200 shadow-xl h-[100px] w-[100px] md:w-[160px] md:h-[160px]  rounded-full object-scale-down bg-center'
                    />
                    <div>
                        <h1 className='text-lg md:text-2xl font-bold text-primaryColor'> {editUser?.name ? editUser.name : 'User'} </h1>
                        <p className='text-sm md:text-base text-gray-500'>{editUser?.email ? editUser.email : 'no email'}</p>
                    </div>
                </div>
            </div>
            <div className='space-y-5'>
                <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-5'>
                    <div className='flex flex-col gap-1 font-cabin'>
                        <span className='font-bold'>Personal info</span>
                        <span className='text-tertiaryColor'>Update your personal details.</span>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:col-span-3 gap-5 bg-white w-full rounded-md overflow-hidden p-5 shadow-md'>
                        <InputField
                            label='Name'
                            placeholder='Name'
                            type='text'
                            id='name'
                            name='name'
                            value={editUser?.name}
                            className={`rounded-md p-2 ${isEdit ? 'border border-gray-300 bg-gray-200' : 'bg-gray-100'}`}
                            disabled={!isEdit}
                            onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}
                        />
                        <InputField
                            label='Email'
                            placeholder='Email'
                            type='text'
                            id='email'
                            name='email'
                            value={editUser?.email}
                            className={`rounded-md p-2 ${isEdit ? 'border border-gray-300 bg-gray-200' : 'bg-gray-100'}`}
                            disabled={!isEdit}
                            onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                        />
                        <InputField
                            label='Username'
                            placeholder='Username'
                            type='text'
                            id='username'
                            name='username'
                            value={editUser?.username}
                            className={`rounded-md p-2 ${isEdit ? 'border border-gray-300 bg-gray-200' : 'bg-gray-100'}`}
                            disabled={!isEdit}
                            onChange={(e) => setEditUser({ ...editUser, username: e.target.value })}
                        />
                        <InputField
                            label='User Type'
                            placeholder='User Type'
                            type='text'
                            id='userType'
                            name='userType'
                            value={

                                editUser?.userType === Number(process.env.REACT_APP_ADMIN) ? "ADMIN" :
                                    editUser?.userType === Number(process.env.REACT_APP_MANAGER) ? "MANAGER" :
                                        editUser?.userType === Number(process.env.REACT_APP_USER) ? "USER" :
                                            "GUEST"
                            }
                            className='border border-gray-300 rounded-md p-2 bg-gray-100'
                            disabled
                        />

                        <div className='lg:col-span-2 space-y-5'>
                            <div className="h-[2px] bg-primaryColor"></div>
                            {isEdit ?
                                <div className='flex gap-5 justify-end flex-col lg:flex-row'>
                                    <button className='min-w-[160px] border border-gray-300 bg-white text-primaryColor py-2 px-5 rounded-md hover:bg-primaryColor hover:text-white transition-all duration-300 ease-in-out'
                                        onClick={handleEdit}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className='min-w-[160px] bg-primaryColor text-secondaryColor py-2 px-5 rounded-md hover:bg-primaryColorLighter transition-all duration-300 ease-in-out'
                                        onClick={handleModalOpen.bind(null, 'edit')}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                                :
                                <div className='flex gap-5 justify-end'>
                                    <button
                                        className='w-[160px] bg-primaryColor text-secondaryColor py-2 px-5 rounded-md hover:bg-primaryColorLighter transition-all duration-300 ease-in-out'
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='space-y-5'>
                <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-5 '>
                    <div className='flex flex-col gap-1 font-cabin'>
                        <span className='font-bold'>Account security</span>
                        <span className='text-tertiaryColor'>Update or manage your account security.</span>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:col-span-3 gap-5 bg-white w-full rounded-md overflow-hidden p-5 shadow-md'>
                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="oldPassword" className='text-primaryColor font-normal'>Old Password</label>
                            <div className="relative w-full">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="oldPassword"
                                    name="oldPassword"
                                    placeholder="Enter old password"
                                    value={oldPassword}
                                    className={`w-full rounded-md py-2 px-2 border border-gray-300`}
                                    onChange={(e) => handleInputPasswordChange(e)}
                                />
                                <div
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="text-primaryColor text-sm absolute right-2 bottom-0 transform -translate-y-1/2 cursor-pointer"
                                >
                                    <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-full gap-2">
                            <label htmlFor="newPassword" className='text-primaryColor font-normal'>New Password</label>
                            <div className="relative w-full">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="newPassword"
                                    name="newPassword"
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    className={`w-full rounded-md py-2 px-2 border ${error ? 'border-red-500' : 'border-gray-300'}`}
                                    onChange={(e) => handleInputPasswordChange(e)}
                                />
                                <div
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="text-primaryColor text-sm absolute right-2 bottom-0 transform -translate-y-1/2 cursor-pointer"
                                >
                                    <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                </div>
                            </div>
                            {error && <p className='text-red-500'>{error}</p>}
                        </div>
                        <div className='lg:col-span-2 space-y-5'>
                            <button
                                className='min-w-[160px] bg-primaryColor text-secondaryColor py-2 px-5 rounded-md hover:bg-primaryColorLighter transition-all duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed'
                                onClick={handleModalOpen.bind(null, 'change-password')}
                                disabled={error ? true : false || !oldPassword || !newPassword}
                            >
                                Change Password
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            {isModalOpen && (
                <Modal
                    title={modalType === 'edit' ? 'Edit User' : 'Change Password'}
                    content={getModalContent()}
                    onClose={handleModalClose}
                    onConfirm={modalType === 'edit' ? handleEditUser : handlePasswordChange}
                    confirmText={modalType === 'edit' ? 'Save Changes' : 'Change Password'}
                    cancelText="Cancel"
                    confirmButtonClass={modalType === 'edit' ? 'bg-primaryColor text-secondaryColor hover:bg-secondaryColor' : 'bg-primaryColor text-secondaryColor hover:bg-secondaryColor'}
                    disableConfirm={
                        modalType === 'edit' ? false : error ? true : !oldPassword || !newPassword

                    }
                />
            )}
        </div>
    );
}

export default Profile;