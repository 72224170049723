import React from 'react';
import { OurLocation, SendMessage } from '../../../components';

const ContactSection: React.FC = () => {
    return (
        <div className='bg-[#e8e8e8] flex items-center py-4 md:py-10'>
            <div className="flex flex-col md:flex-row gap-10 justify-center container mx-auto overflow-hidden">
                <div
                    className="w-full md:w-1/2 space-y-5"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                >
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-primaryColor">Contact Details</h1>

                    <div className='flex items-center space-x-4'>
                        <i className="fas fa-map-marker-alt text-2xl text-tertiaryOrange w-5"></i>
                        <p>
                            Royal Institute Epsom
                            13/17 Margot Street
                            Epsom
                            Auckland 1051
                        </p>
                    </div>
                    <div className='flex items-center space-x-4'>
                        <i className="fas fa-mobile-alt text-2xl text-tertiaryOrange w-5"></i>
                        <a href="tel:+64 27 388 4723">(+64) 27 388 4723 (office)</a>
                    </div>
                    <div className='flex items-center space-x-4'>
                        <i className="fas fa-phone-alt text-2xl text-tertiaryOrange w-5"></i>
                        <a href="tel:+64 9 963 9936"> (+64) 9 963 9936 (fax)</a>
                    </div>
                    <div className='flex items-center space-x-4'>
                        <i className="fas fa-envelope text-2xl text-tertiaryOrange w-5"></i>
                        <a href="mailto:info.royalepsom@gmail.com">info.royalepsom@gmail.com</a>
                    </div>
                    <div className="mt-4">
                        <OurLocation />
                    </div>
                </div>
                <div
                    className="w-full md:w-1/2 space-y-5"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                >
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-tertiaryOrange">Contact Us</h1>
                    <SendMessage />
                </div>
            </div>
        </div >
    );
};

export default ContactSection;
