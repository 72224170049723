import React from 'react'
import Modal from '../../modal';
import { GET } from '../../../auth/AxiosHelper';
import { PosterIf } from '../../../../types';

interface ProductCardProps {
    data: PosterIf[];
    isSlice?: boolean;
    start?: number;
    end?: number;
    isAdmin?: boolean;
    handleModalOpen?: (type: string, data: PosterIf) => void;
}
const ProductCard: React.FC<ProductCardProps> = ({ data, isSlice, start = 0, end, isAdmin = false, handleModalOpen }) => {
    const [product, setProduct] = React.useState<PosterIf>();
    const [selectedProduct, setSelectedProduct] = React.useState<PosterIf>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    const fetchProduct = async (id: string) => {
        try {
            setSelectedProduct(data.find(item => item._id === id))
            setIsLoading(true)
            const res = await GET(`/poster/one/${id}`);
            if (res.data) {
                const data = {
                    ...res.data.data,
                    image: process.env.REACT_APP_API_URL + '/' + res.data.data.image
                };
                setProduct(data)
                setIsModalOpen(true)
                setIsLoading(false)
            }
        } catch (error) {
            setIsModalOpen(false)
            setIsLoading(false)
            console.log("error", error);
        }
    }


    const getModalContent = () => {
        return (
            <div>
                <img
                    src={product?.image}
                    alt='product-image'
                    className="w-full h-full  object-scale-down"
                />
            </div>
        )
    }



    return (
        <>
            {(isSlice ? data?.slice(start, end) : data)?.map((item, index) => {
                return (
                    <div className='flex flex-col justify-between border border-bgGray pb-3 bg-white rounded-lg  relative
                    hover:shadow-lg transform transition-all duration-300 hover:scale-105 cursor-pointer'
                        key={index}
                    >
                        {isLoading && selectedProduct?._id === item._id &&
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primaryColor"></div>
                            </div>
                        }

                        <div
                            onClick={() => fetchProduct(item._id)}
                            className='min-h-[320px] w-full bg-gray-300 rounded-t-lg relative overflow-hidden'
                        >
                            <div className='absolute top-0 left-0 bg-primaryColor text-white px-2 py-1 rounded-br-lg'>
                                {item.order}
                            </div>
                            <img
                                src={item.image}
                                alt='product-image'
                                className="w-full h-auto object-scale-down"
                            />
                        </div>

                        {isAdmin &&
                            <div className='flex justify-between pt-4 px-5'>
                                <div
                                    className='text-primaryColor cursor-pointer hover:text-secondaryColor  transform transition-all duration-300 ease-in-out'
                                    onClick={() => handleModalOpen && handleModalOpen('edit', item)}
                                >
                                    <i className="fas fa-edit"></i>
                                </div>
                                <div
                                    className={`text-${item.isActive ? 'red' : 'green'}-500 cursor-pointer hover:text-secondaryColor  transform transition-all duration-300 ease-in-out`}
                                    onClick={() => handleModalOpen && handleModalOpen('delete', item)}
                                >
                                    {item.isActive ? 'Deactivate' : 'Activate'}
                                </div>
                                <div
                                    className='text-primaryColor cursor-pointer hover:text-secondaryColor  transform transition-all duration-300 ease-in-out'
                                    onClick={() => handleModalOpen && handleModalOpen('delete', item)}
                                >
                                    <i className="fas fa-trash"></i>
                                </div>
                            </div>
                        }
                    </div>
                )
            })}

            {isAdmin && isModalOpen && (
                <Modal
                    title="View Poster"
                    content={getModalContent()}
                    onClose={() => {
                        setIsModalOpen(false);
                        setProduct(undefined);
                    }}
                    onConfirm={() => { }}
                    cancelText="Close"
                    confirmButtonClass="bg-primaryColor text-white"
                    size='sm'
                />
            )}
        </>
    )
}

export default ProductCard
