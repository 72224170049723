import React from 'react'

const courses = [
    {
        id: 1,
        title: 'A2 - Cambridge',
        courses: [
            'Maths',
            'Physics',
            'Chemistry',
            'Biology',
            'English',
            'Accounting',
            'Economics'
        ]
    },
    {
        id: 2,
        title: 'AS - Cambridge',
        courses: [
            'Maths',
            'Physics',
            'Chemistry',
            'Biology',
            'English',
            'Accounting',
            'Economics'
        ]
    },
    {
        id: 3,
        title: 'L3 - NCEA',
        courses: [
            'Maths',
            'Statistics',
            'Physics',
            'Chemistry',
            'Biology',
            'English',
            'Accounting',
            'Economics'
        ]
    },
    {
        id: 4,
        title: '12 - NCEA',
        courses: [
            'Maths',
            'Statistics',
            'Physics',
            'Chemistry',
            'Biology',
            'English',
            'Accounting',
            'Economics'
        ]
    },
    {
        id: 5,
        title: 'Year 11',
        courses: [
            'Maths',
            'Statistics',
            'Physics',
            'Chemistry',
            'Biology',
            'English',
            'Accounting'
        ]
    },
    {
        id: 6,
        title: 'Year 10',
        courses: [
            'Maths',
            'Science',
            'English'
        ]
    },
    {
        id: 7,
        title: 'Year 04 to 09 ar 10',
        courses: [
            'Maths',
            'Science',
            'English'
        ]
    }
]

// Subjects
// Other subjects may offer on-demand, call the office
// Duration
// February to November, Weekly classes,
// Weekends/weekdays
// » Small Groups
// Class size is small and you will benefit from just like one-on-one tutoring
// » Time
// English one-hour other subjects' two-hour classes or tutors will inform
// » Fees
// Monthly pay to the institute account, no cash payments
// Year 07 to 09
// Year 10
// Year 11
// : $30/class, pay monthly : $35/class, pay monthly : $40/class, pay monthly
// A2, AS, L3 NCEA, L2 NCEA : $45/class, pay monthly

const subjects = [
    {
        id: 1,
        title: 'Subjects',
        subTitles: 'Other subjects may offer on-demand, call the office'
    },
    {
        id: 2,
        title: 'Duration',
        subTitles: 'February to November, Weekly classes, Weekends/weekdays'
    },
    {
        id: 3,
        title: 'Small Groups',
        subTitles: 'Class size is small and you will benefit from just like one-on-one tutoring'
    },
    {
        id: 4,
        title: 'Time',
        subTitles: 'English one-hour other subjects\' two-hour classes or tutors will inform'
    },
    {
        id: 5,
        title: 'Fees',
        subTitles: 'Monthly pay to the institute account, no cash payments',
        fee: [
            {
                id: 1,
                title: 'Year 07 to 09',
                value: '$30/class, pay monthly'
            },
            {
                id: 2,
                title: 'Year 10',
                value: '$35/class, pay monthly'
            },
            {
                id: 3,
                title: 'Year 11',
                value: '$40/class, pay monthly'
            },
            {
                id: 4,
                title: 'A2, AS, L3 NCEA, L2 NCEA',
                value: '$45/class, pay monthly'
            }
        ]
    }
]

const CourseSection: React.FC = () => {
    return (
        <div>
            <section className='bg-primaryColor'>
                <div className="overflow-hidden grid grid-cols-1 lg:grid-cols-2">
                    <div className="px-12 md:px-28 py-20 md:py-20 flex flex-col justify-center w-full space-y-10 order-2 md:order-1" data-aos="fade-right" data-aos-duration="1300">
                        <div className="text-2xl md:text-4xl space-y-5">
                            <div className="text-tertiaryOrangeLight">
                                High School Programmes  <br /><span className="text-white font-bold">
                                    ( Small Groups )
                                </span>
                            </div>
                            <div className="text-tertiaryOrangeLight">
                                Face-To-Face <span className="text-white font-bold">
                                    or Online Learning
                                </span>
                            </div>
                        </div>
                        <div className='space-y-5'>
                            {courses.map((course, index) => (
                                <div key={index} className="flex gap-4">
                                    <div>
                                        <img src="assets/images/icons/Chevron.png"
                                            alt="Our Goals"
                                            className="w-5 h-5 mt-1"
                                        />
                                    </div>
                                    <div className='text-white space-y-3'>
                                        <span className="font-bold text-xl">{course.title}</span>
                                        <p className='font-thin text-lg'>{course.courses.join(', ')}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full flex items-center order-1 md:order-2" data-aos="fade-left" data-aos-duration="1300" >
                        <img
                            src="assets/images/background/bg-image-24.png"
                            alt="Our Vision"
                            className='w-full h-full object-cover'
                        />
                    </div>
                </div>
            </section>
            <section className='bg-primaryColor'>
                <div className="overflow-hidden grid grid-cols-1 lg:grid-cols-2">
                    <div className="w-full flex items-center order-1 md:order-1" data-aos="fade-right" data-aos-duration="1300">
                        <img
                            src="assets/images/background/bg-image-23.png"
                            alt="Our Vision"
                            className='w-full h-full object-cover'
                        />
                    </div>
                    <div className="px-12 md:px-28 py-20 md:py-20 flex flex-col justify-center w-full space-y-10 order-2 md:order-2" data-aos="fade-left" data-aos-duration="1300">
                        <div className="text-2xl md:text-4xl">
                            <div className="text-tertiaryOrangeLight">
                                Tutoring is for enrichment and it's all about <span className="text-white font-bold">
                                    improvement, growth and gaining more confidence!
                                </span>
                            </div>
                        </div>
                        <div className='space-y-5'>
                            {subjects.map((subject, index) => (
                                <div key={index} className="flex gap-4">
                                    <div>
                                        <img src="assets/images/icons/Chevron.png"
                                            alt="Our Goals"
                                            className="w-5 h-5 mt-1"
                                        />
                                    </div>
                                    <div className='text-white space-y-3'>
                                        <span className="font-bold text-xl">{subject.title}</span>
                                        <p className='font-thin text-lg'>{subject.subTitles}</p>
                                        {subject.fee && (
                                            <div className='space-y-2  text-lg'>
                                                {subject.fee.map((fee, index) => (
                                                    <div key={index} className='flex flex-col sm:flex-row gap-2 w-full flex-wrap'>
                                                        <span className='min-w-[230px] flex gap-2'>{fee.title}<span className='block sm:hidden'>: </span> </span>
                                                        <span className='hidden sm:block'>: </span>
                                                        <span className='pt-3 sm:pb-0 md:pb-3 xl:pb-0'>  {fee.value}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default CourseSection