import React from 'react';

const importantLinks = [
    {
        id: 1,
        title: 'NCEA Subject Resources',
        link: 'https://www.nzqa.govt.nz/ncea/subjects/'
    },
    {
        id: 2,
        title: 'Cambridge International AS and A Level subjects',
        link: 'https://www.cambridgeinternational.org/programmes-and-qualifications/cambridge-advanced/cambridge-international-as-and-a-levels/subjects/'
    },
    {
        id: 3,
        title: 'IELTS Find a Test Location',
        link: 'https://www.ielts.org/book-a-test/find-a-test-location/location-list/new-zealand/tv'
    },
    {
        id: 4,
        title: 'IELTS for Migration New Zealand',
        link: 'https://www.ielts.org/what-is-ielts/ielts-for-migration/new-zealand'
    }
];

const contactOffice = [
    {
        id: 1,
        title: 'Individual tutoring & online classes',
    },
    {
        id: 2,
        title: 'Special offers',
    },
    {
        id: 3,
        title: 'Scholarships',
    }
];

const ImportantLinkSection: React.FC = () => {
    return (
        <section
            className="text-center overflow-hidden py-12 md:py-20 min-h-[calc(100vh-4rem)]"
            style={{ backgroundImage: 'url(assets/images/background/bg-image-20.png)', backgroundSize: 'cover' }}
        >
            <div className="container mx-auto flex flex-col justify-center gap-10">
                <div className='space-y-6' data-aos="fade-right" data-aos-duration="1300" >
                    <div className="text-2xl md:text-4xl flex items-center gap-2">
                        <div className="text-tertiaryOrangeLight">
                            Our
                        </div>
                        <div className="text-primaryColor font-bold">
                            Fees
                        </div>
                    </div>
                    <div className='mt-4 flex flex-col w-full'>
                        {importantLinks.map((link, index) => (
                            <div
                                key={index}
                                className="flex gap-4 space-y-5 w-full pr-10"
                                data-aos="fade-right"
                                data-aos-duration="1300"
                                data-aos-delay={index * 100}
                            >

                                <img src="assets/images/icons/Chevron.png"
                                    alt="Our Goals"
                                    className="w-5 h-5 mt-6"
                                />
                                <div className='flex flex-wrap px-2 w-full break-words'

                                >
                                    <span className="text-primaryColor text-left font-bold text-xl whitespace-normal">{link.title}</span>
                                    <a
                                        href={link.link}
                                        className="font-thin w-full text-left text-lg text-primaryColorLight hover:underline break-words"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {link.link}
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='space-y-6' data-aos="fade-right" data-aos-duration="1300" >
                    <div className="text-2xl md:text-4xl flex items-center gap-2">
                        <div className="text-tertiaryOrangeLight">
                            Contact the
                        </div>
                        <div className="text-primaryColor font-bold">
                            Office for
                        </div>
                    </div>
                    <div className="mt-4 text-left text-[18px]" data-aos="fade-right" data-aos-duration="1300" >
                        {contactOffice.map((link, index) => (
                            <div
                                key={index}
                                className="flex gap-4 space-y-5 w-full pr-10"
                                data-aos="fade-right"
                                data-aos-duration="1300"
                                data-aos-delay={index * 100}
                            >

                                <img src="assets/images/icons/Chevron.png"
                                    alt="Our Goals"
                                    className="w-5 h-5 mt-6"
                                />
                                <div className='flex flex-wrap px-2 w-full break-words'

                                >
                                    <span className="text-primaryColor text-left font-bold text-xl whitespace-normal">{link.title}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='space-y-6'>
                    <div className="text-2xl md:text-4xl flex items-center gap-2" data-aos="fade-right" data-aos-duration="1300" >
                        <div className="text-tertiaryOrangeLight">
                            Contact the
                        </div>
                        <div className="text-primaryColor font-bold">
                            Office for
                        </div>
                    </div>
                    <div className="mt-4 text-left text-[18px]" data-aos="fade-right" data-aos-duration="1300" >
                        <div className="flex gap-2">
                            <img src="assets/images/icons/Chevron.png"
                                alt="Our Goals"
                                className="w-5 h-5 mt-1"
                            />
                            <div className='flex flex-wrap px-2 w-full break-words'>
                                <span className="text-primaryColor text-left font-bold text-xl whitespace-normal">The fees displayed on this website are subject to change without notice</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ImportantLinkSection;
