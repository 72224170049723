import React from 'react'
import { ProductCard } from '../../../components';
import { PosterIf } from '../../../../types';
interface ViewProductsProps {
    products: PosterIf[];
    handleModalOpen: (type: string, data: PosterIf) => void;
}

const ViewProducts: React.FC<ViewProductsProps> = ({ products, handleModalOpen }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10'>
            <ProductCard data={products} isAdmin handleModalOpen={handleModalOpen} />
        </div>
    )
}

export default ViewProducts
