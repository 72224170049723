import React, { useEffect } from 'react'
import { GET } from '../../../auth/AxiosHelper';
import { PosterIf } from '../../../../types';
import Loading from '../../../../global/loading';

const PosterSection: React.FC = () => {
    const [posters, setPosters] = React.useState<PosterIf[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const response = await GET('/poster/all');
            if (response.status === 200) {
                const data = response.data.data.map((item: PosterIf) => ({
                    ...item,
                    // image: process.env.REACT_APP_API_URL + '/' + item.image
                    image: process.env.REACT_APP_API_URL + '/' + item.image
                }));
                setPosters(data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    if (loading) {
        return (
            <Loading />
        )
    }

    if (posters.length === 0) {
        return (
            <div className="hidden"> </div>
        )
    }

    return (
        <div className='min-h-[100vh]  py-4 md:py-10 flex items-center'>
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                    {posters && posters.length > 0 ? posters.map((poster, index) => (
                        <div
                            key={index}
                            className=""
                            data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                            data-aos-duration="1000"
                            data-aos-delay="100"
                        >
                            <div className="relative">
                                <img
                                    src={poster.image}
                                    alt={`poster-${index}`}
                                    className="w-full  object-cover rounded-lg"
                                />

                            </div>
                        </div>
                    )) : (
                        <div className="md:col-span-2 flex justify-center items-center">
                            <p className="text-2xl font-bold">No poster available</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PosterSection
