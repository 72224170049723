import React from 'react'
const AboutSectionSecond: React.FC = () => {
    return (
        <div className='bg-secondaryColor flex items-center py-4 md:py-10'
            style={{ backgroundImage: 'url(assets/images/background/bg-image-7.png)', backgroundSize: 'cover' }}
        >
            <div className="flex flex-col md:flex-row gap-4 items-center justify-center container mx-auto overflow-hidden">
                <div
                    className="relative w-full text-justify space-y-10"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                >

                    <p>
                        RIE is located within walking distance from most of the prestigious high schools in Auckland. Thus, students need not to worry about transport as they can walk to RIE classes. On the other hand, it makes students feel fresh and relaxed when they arrive at the class. It helps them to focus on their lessons. Adult students have the advantage of easy access to trains and busses who are coming after work.
                    </p>
                    <p>
                        In order to support individual requirements, the Auckland Campus has a flexible enrolling system for all students.
                    </p>
                    <p>
                        We invite you to join RIE and make your educational journey a success.
                    </p>
                </div>
                <div
                    className="w-full flex justify-center"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                >
                    <div className="relative w-full">
                        <img
                            src="assets/images/background/bg-image-6.png"
                            alt="Background  5"
                            className=" w-[100%]"
                        />
                        <div className="absolute w-[100%] h-10 justify-center z-[50] bottom-0 flex">
                            <div className='bg-gradient-to-t from-[#d4d4d45e] to-transparent h-10 w-[100%]'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSectionSecond