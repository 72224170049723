// src/components/Management.js
import React from 'react';

const Management: React.FC = () => {
    return (
        <section className="py-12 md:py-10 bg-white">
            <div className="container mx-auto">
                <div className='flex flex-col w-full items-center' data-aos="fade-right" data-aos-duration="1300" >
                    <div className='flex flex-col'>
                        <h2 className="text-3xl font-bold text-primaryColor leading-8">Management</h2>
                        <div className='h-1 w-20 bg-tertiaryOrangeLight mt-3 mb-6'></div>
                    </div>
                </div>
                <p className="mt-4 text-justify" data-aos="fade-left" data-aos-duration="1300" >
                    RIE is managed by a qualified and experienced team. Director,
                    Dr. Sydney Fernando worked as a Senior Advisor in the Ministry of Agriculture and Forestry in Wellington, NZ,
                    and later joined the education sector in Auckland. He managed Educational Institutes over 13 years in Auckland and worked as a Director of Studies,
                    Associate Principal, and as a General Manager. Thus, understand the NZ education system and experience in managing them. Further,
                    well experienced in the NZ professional pathways.
                    Teachers are also professionals from respective subject areas and bring the experience and current world challenges to the classroom with their own experience.
                    In order to guide students better,
                    parents and students are encouraged to discuss their academic pathways and subjects with tutors.
                </p>
            </div>
        </section>
    );
};

export default Management;
