import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AppProvider } from '../contexts/AppContext';
import PrivateRoute from './auth/PrivateRoute';
import { Error404Page, Login, Register, Unauthorized } from './main';
import { About, Programmes, Home } from './pages';
import { Dashboard, ProfileAdmin, AdminProducts, Email } from './admin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App: React.FC = () => {
  return (
    <AppProvider>
      <HashRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<PrivateRoute element={<Home />} roles={['ADMIN', 'MANAGER', 'USER', 'GUEST']} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/about-us" element={<PrivateRoute element={<About />} roles={['ADMIN', 'MANAGER', 'USER', 'GUEST']} />} />
          <Route path="/programmes" element={<PrivateRoute element={<Programmes />} roles={['ADMIN', 'MANAGER', 'USER', 'GUEST']} />} />

          {/* <Route path="/admin/users" element={<PrivateRoute element={<Users />} roles={['ADMIN']} />} /> */}
          <Route path="/admin/dashboard" element={<PrivateRoute element={<Dashboard />} roles={['ADMIN', 'MANAGER']} />} />
          <Route path="/admin/products" element={<PrivateRoute element={<AdminProducts />} roles={['ADMIN', 'MANAGER']} />} />
          <Route path="/admin/profile" element={<PrivateRoute element={<ProfileAdmin />} roles={['ADMIN', 'MANAGER']} />} />
          <Route path="/admin/emails" element={<PrivateRoute element={<Email />} roles={['ADMIN', 'MANAGER']} />} />

          {/* Common Routes */}
          <Route path="*" element={<Error404Page />} />
          <Route path="unauthorized" element={<Unauthorized />} />
        </Routes>
      </HashRouter>
    </AppProvider>
  );
};

export default App;
