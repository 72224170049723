import React from 'react'

const ieltsData = [
    {
        id: 1,
        name: 'IELTS',
        type: 'Academic',
        details: [
            {
                id: 1,
                name: 'Duration',
                value: '20 weeks'
            },
            {
                id: 2,
                name: 'Class hours',
                value: '40 hours ( 20 x 2 hour classes )'
            },
            {
                id: 3,
                name: 'Individual Practice',
                value: 'Extra 02 hours/week'
            },
            {
                id: 4,
                name: 'Maximum number',
                value: '12 students per class, just like one-on-one'
            },
            {
                id: 5,
                name: 'Course fee',
                value: '$1200'
            }
        ]
    },
    {
        id: 2,
        name: 'IELTS',
        type: 'General',
        details: [
            {
                id: 1,
                name: 'Duration',
                value: '24 weeks'
            },
            {
                id: 2,
                name: 'Class hours',
                value: '48 hours ( 24 x 2 hour classes )'
            },
            {
                id: 3,
                name: 'Individual Practice',
                value: 'Extra 02 hours/week'
            },
            {
                id: 4,
                name: 'Maximum number',
                value: '12 students per class, just like one-on-one'
            },
            {
                id: 5,
                name: 'Course fee',
                value: '$1400'
            }
        ]
    }
]

const description = [
    {
        id: 1,
        name: 'Classes are on Weekends/Weekdays',
    },
    {
        id: 2,
        name: 'Valid visa Required',
    },
    {
        id: 3,
        name: 'Pay feed before start or two Installments',
    },
    {
        id: 4,
        name: 'Textbook/Workbook to be purchased',
    },
    {
        id: 5,
        name: 'Refund policy : After 1st week - $1100, 2nd week - $1000, 3rd week - $900 and after 4th week no refund',
    }
]

const IeltsSection: React.FC = () => {

    return (
        <section className="overflow-hidden">
            <div className="container mx-auto py-12 md:py-10 bg-white ">
                <div className='flex flex-col w-full items-center' data-aos="fade-right" data-aos-duration="1000" >
                    <div className='flex flex-col'>
                        <h2 className="text-3xl md:text-4xl font-bold text-primaryColor leading-8">IELTS -  Two Separate Programmes</h2>
                        <div className='h-1 w-20 bg-tertiaryOrangeLight mt-3 mb-6'></div>
                    </div>
                </div>
                <div className="mt-4 text-justify flex flex-col lg:flex-row gap-10 overflow-hidden">
                    {ieltsData.map((data, index) => {
                        return (
                            <>
                                <div key={data.id} className='w-full lg:w-1/2'
                                    data-aos={index === 0 ? 'fade-right' : 'fade-left'}
                                    data-aos-duration={index === 0 ? '1300' : '1300'}
                                    data-aos-delay={index === 0 ? '200' : '200'}

                                >
                                    <div className="text-2xl md:text-3xl flex items-center gap-2">
                                        <div className="text-tertiaryOrangeLight font-light">
                                            {data.name}
                                        </div>
                                        <div className="text-primaryColor font-bold">
                                            {data.type}
                                        </div>
                                    </div>
                                    <div className='mt-9'>
                                        <div className='space-y-4'>
                                            {data.details.map((detail) => {
                                                return (
                                                    <div key={detail.id} className='flex gap-2 w-full'>
                                                        <img
                                                            src="assets/images/icons/Chevron.png"
                                                            alt="icon"
                                                            className='w-[0.8rem] h-[0.8rem] mt-1 inline-block'
                                                        />
                                                        <div className='flex gap-2 w-full'>
                                                            <span className='font-bold min-w-40'>{detail.name} </span>
                                                            <span>: </span>
                                                            <span className='font-thin'>{detail.value}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {index === 0 && (
                                    <>
                                        <div className='border-tertiaryOrange border-[1px] block lg:hidden' data-aos="fade-left" data-aos-duration="1300" data-aos-delay="200" />
                                        <div className='border-tertiaryOrange border-[1px] hidden lg:block' data-aos="fade-up" data-aos-duration="1300" data-aos-delay="200" />
                                    </>
                                )}
                            </>
                        )
                    })}
                </div>
            </div>
            <div className='bg-[#EBEBEB] py-12 md:py-10'>
                <div className="container mx-auto text-justify flex flex-col lg:flex-row gap-10">
                    <div className='w-full lg:w-1/2 space-y-4 order-2 lg:order-1'>
                        {description.map((data, index) => {
                            return (
                                <div key={index} className='flex gap-3 w-full'
                                    data-aos="fade-right"
                                    data-aos-duration="1300"
                                    data-aos-delay={200 + (index * 100)}
                                >
                                    <img
                                        src="assets/images/background/bg-image-16.png"
                                        alt="icon"
                                        className='w-[1.2rem] h-[1.2rem] mt-1 inline-block'
                                    />
                                    <div className='flex gap-2 w-full'>
                                        <span className='font-thin min-w-40'>{data.name}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='w-full lg:w-1/2 order-1 lg:order-2'>
                        <img
                            src="assets/images/background/bg-image-22.png"
                            alt="IELTS"
                            className='w-full h-full object-cover'
                            data-aos="fade-left"
                            data-aos-duration="1300"
                            data-aos-delay="200"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IeltsSection
