import React from 'react';
import HeroSection from './widgets/heroSection';
import AboutSection from './widgets/aboutSection';
import AboutSectionSecond from './widgets/aboutSectionSecond';
import ContactSection from './widgets/contactSection';
import PosterSection from './widgets/posterSection';

const Home: React.FC = () => {

    return (
        <>
            <HeroSection />
            <AboutSection />
            <AboutSectionSecond />
            <PosterSection />
            <ContactSection />
        </>
    );
};

export default Home;
