import React, { useEffect, useState } from "react";
import navigationConfig from "../../routes/navigationConfig";
import NavBar from "../navBar/navBar";
import Footer from "../footer/footer";
import FloatingButtons from "../buttons/floating-button";

interface UserLayoutProps {
    children: React.ReactNode;
}

const UserLayout: React.FC<UserLayoutProps> = ({ children }) => {
    const [scroll, setScroll] = useState(0);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const scrollToBottom = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [scroll]);

    return (
        <div className="flex min-h-screen justify-between flex-col bg-shade">
            <NavBar navigation={navigationConfig} />
            <div className="min-h-screen">
                {children}
            </div>
            <Footer />
            <div className="fixed bottom-5 right-5 z-50 flex flex-col space-y-2">
                {scroll > 100 ?
                    <FloatingButtons onClick={scrollToTop} icon="fas fa-chevron-up" divOrAnchor="div" />
                    :
                    <FloatingButtons onClick={scrollToBottom} icon="fas fa-chevron-down" divOrAnchor="div" />
                }
            </div >
        </div >
    );
}

export default UserLayout;
