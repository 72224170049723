import React from 'react'

const ProgrammesHeader: React.FC = () => {
    return (
        <section className="pt-[calc(4rem+6px)] bg-[#DEE7E3]" >
            <section
                className="container mx-auto flex flex-col md:flex-row  gap-5 md:gap-12 overflow-hidden py-10"
            >
                <div className="w-full md:w-1/2 space-y-10 order-2 md:order-1 py-4 md:py-16" >
                    <div className="flex flex-col gap-2 items-center md:items-start text-5xl md:text-5xl xl:text-7xl" >
                        <div className="font-bold text-tertiaryOrangeLight leading-[1.4] text-center md:text-left" data-aos="fade-right" data-aos-duration="1300"  >
                            Royal Institute
                        </div>
                        <div className="font-bold text-primaryColor" data-aos="fade-left" data-aos-duration="1300" >
                            Programmes
                        </div>
                    </div>
                    <div className="mt-4 text-center md:text-left" data-aos="fade-up" data-aos-duration="1300" >
                        Join us to learn, grow, and achieve your goals with our programmes.
                    </div>
                </div>
                <div className="w-[80%] mx-auto md:w-1/2 flex justify-center items-center relative order-1 md:order-2" data-aos="zoom-in" data-aos-duration="1300">
                    <img
                        src="assets/images/background/bg-image-21.png"
                        alt="Our Vision"
                        className='z-[2] w-full'
                    />
                    <img
                        src="assets/images/background/bg-image-13.png"
                        alt="Our Mission"
                        className='absolute top-0 md:top-20 xl:top-0 right-0 w-[50%]  object-cover z-[1]'
                    />
                </div>
            </section>
        </section>
    )
}

export default ProgrammesHeader