/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { AdminLayout, UserLayout } from '../components';
import adminNavigationConfig from '../routes/adminNavigationConfig';

interface PrivateRouteProps {
  element: React.ReactElement;
  roles: string[] | 'ADMIN' | 'MANAGER' | 'USER' | 'GUEST';
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, roles }) => {
  const location = useLocation();
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  // const location = useLocation();
  const isAuthenticated = !!localStorage.getItem('accessToken');

  const userType =
    user?.userType === Number(process.env.REACT_APP_ADMIN)
      ? 'ADMIN'
      : user?.userType === Number(process.env.REACT_APP_MANAGER)
        ? 'MANAGER'
        : user?.userType === Number(process.env.REACT_APP_USER)
          ? 'USER'
          : 'GUEST';

  const hasRequiredRole = roles.includes(userType);

  useEffect(() => {
    const checkUserState = async () => {
      setIsLoading(false);
    };

    checkUserState();
  }, [user, roles, isAuthenticated, hasRequiredRole]);

  if (isLoading) {
    return <div>Loading...</div>;
  }


  if ((!isAuthenticated || roles !== 'GUEST') && !hasRequiredRole) {
    if (!hasRequiredRole) {
      return <Navigate to="/unauthorized" />;
    }
    return <Navigate to="*" />;
  }

  // if (!isAuthenticated) {
  //   // Redirect to login page if not authenticated
  //   return <Navigate to="/admin-login" state={{ from: location }} />;
  // }

  // if (!hasRequiredRole) {
  //   // Redirect to unauthorized page if user doesn't have the required role
  //   return <Navigate to="/unauthorized" />;
  // }

  // adminNavigationConfig if path only show admin layout and if uerType is ADMIN but other path show user layout

  // if path is in adminNavigationConfig show admin layout and if userType is ADMIN else show user layout 
  const adminRoutes = adminNavigationConfig.map((item) => item.path);
  // return userType === 'ADMIN' && adminRoutes.includes(location.pathname) ? (
  return (userType === 'ADMIN' || userType === 'MANAGER') && adminRoutes.includes(location.pathname) ? (
    <AdminLayout>{element}</AdminLayout>
  ) : (
    <UserLayout>{element}</UserLayout>
  );
};


// return userType === 'ADMIN' ? (
//   <AdminLayout>{element}</AdminLayout>
// ) : (
//   <UserLayout>{element}</UserLayout>
// );
// };

export default PrivateRoute;
