import React from "react";

const Footer: React.FC = () => {
    return (
        <footer>
            <div
                className="text-white py-4 md:py-10"
                style={{ backgroundImage: 'url(assets/images/background/bg-image-7.png)', backgroundSize: 'cover' }}
            >
                <div className="container mx-auto py-6 space-y-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 md:gap-5 lg:gap-10">
                        <div className="w-full flex flex-col items-start gap-5">
                            <div className="flex flex-col gap-2 w-full">
                                <div className="flex items-center gap-3">
                                    <img
                                        className="h-[45px]"
                                        src="assets/images/logo/royal-institute-logo.png"
                                        alt="go bucks logo footer"
                                    />
                                    <span className="text-xl font-bold text-primaryColor">
                                        Royal Institute Epsom
                                    </span>
                                </div>
                                <ul className="list-reset capitalize flex items-center justify-start md:pl-12 gap-5 w-full">
                                    <li>
                                        <a
                                            href="https://www.facebook.com/"
                                            target="_blank"
                                            className="text-white hover:text-secondaryColor flex items-center justify-center bg-tertiaryOrange p-2 rounded-full w-8 h-8"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-facebook-f ml-1"></i> &nbsp;
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/"
                                            target="_blank"
                                            className="text-white hover:text-secondaryColor flex items-center justify-center bg-tertiaryOrange p-2 rounded-full w-8 h-8"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-twitter ml-1"></i> &nbsp;
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/"
                                            target="_blank"
                                            className="text-white hover:text-secondaryColor flex items-center justify-center bg-tertiaryOrange p-2 rounded-full w-8 h-8"
                                            rel="noreferrer"
                                        >
                                            <i className="fab fa-linkedin ml-1"></i> &nbsp;
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full flex flex-col items-start gap-2">
                            <span className="text-xl font-bold text-primaryColor">
                                Office Location
                            </span>
                            <a
                                href="https://maps.google.com/maps?ll=-36.877967,174.777405&z=10&t=m&hl=en-US&gl=US&mapclient=embed&q=17%20Margot%20Street%20Epsom%20Auckland%201051%20New%20Zealand"
                                target="_blank"
                                className='flex space-x-4'
                                rel="noreferrer"
                            >
                                <i className="fas fa-map-marker-alt text-2xl text-tertiaryOrange w-5"></i>
                                <p className="text-primaryColor">
                                    Royal Institute Epsom, <br />
                                    13/17 Margot Street, <br />
                                    Epsom, <br />
                                    Auckland 1051, <br />
                                </p>
                            </a>
                        </div>
                        <div className="w-full flex flex-col items-start gap-2">
                            <span className="text-xl font-bold text-primaryColor">
                                Contact Details
                            </span>
                            <div className='flex items-center space-x-4'>
                                <i className="fas fa-mobile-alt text-2xl text-tertiaryOrange w-5"></i>
                                <a href="tel:+64 27 388 4723" className="text-primaryColor">(+64) 27 388 4723 (office)</a>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <i className="fas fa-phone-alt text-2xl text-tertiaryOrange w-5"></i>
                                <a href="tel:+64 9 963 9936" className="text-primaryColor"> (+64) 9 963 9936 (fax)</a>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <i className="fas fa-envelope text-2xl text-tertiaryOrange w-5"></i>
                                <a href="mailto:info.royalepsom@gmail.com" className="text-primaryColor">info.royalepsom@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full text-xs flex text-white flex-col md:flex-row justify-center bg-primaryColor py-4">
                <p className="text-center">
                    © Copyright 2024 |
                    Royal Institute Epsom
                    | All Rights Reserved
                </p>
                <p className="text-center pl-2">
                    Developed By
                    <a
                        href="https://pramudnadula.com/"
                        target="_blank"
                        className="pl-2 text-blue-500 hover:text-secondaryColor"
                        rel="noreferrer"
                    >
                        Pramud Nadula
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
