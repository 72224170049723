import React from 'react'
const AboutSection: React.FC = () => {
    return (
        <div className="py-4 md:py-10 flex flex-col md:flex-row gap-4 items-center justify-center min-h-[calc(100vh-4rem)] container mx-auto overflow-hidden">
            <div
                className="w-full flex justify-center"
                data-aos="fade-right"
                data-aos-duration="1500"
            >
                <div className="relative w-full">
                    <img
                        src="assets/images/background/bg-image-5.png"
                        alt="Background  5"
                        className=" w-[80%]"
                    />
                    <div className="absolute w-[100%] h-10 justify-center z-[50] bottom-0 flex">
                        <div className='bg-gradient-to-t from-white to-transparent h-10 w-[75%]'></div>
                    </div>
                </div>
            </div>
            <div
                className="relative w-full text-justify space-y-10"
                data-aos="fade-left"
                data-aos-duration="1500"
            >
                <h1 className="text-3xl md:text-4xl lg:text-5xl  text-left font-bold text-primaryColor">
                    Royal Institute Epsom
                </h1>
                <p>
                    Royal Institute Epsom (RIE) campus is situated in the heart of Epsom in Auckland city. Auckland is the largest and most vibrant city in New Zealand. It offers a truly authentic Kiwi as well as international experience.
                </p>
                <p>
                    RIE staff help individual students to realise their potential, using the education system with their support and encouragement. Further, Royal Institute Epsom’s programmes NCEA, Cambridge, English Language, and IELTS are well organised and focused on student’s expectations, which means students can finish their studies much comfortably, effectively, and earlier than the traditional timeframe. Obviously with good results.
                </p>
                <p>
                    RIE class sizes are small and students will benefit from just like one-on-one tuition and extra learning support from RIE experienced, qualified tutors. From the outset, our friendly student support staff will guide and provide students with all the information students require about studying at the Epsom Campus.
                </p>
            </div>
        </div>
    )
}

export default AboutSection